import React from 'react';
import { Container, Typography, Grid, Card, CardContent, CardActionArea } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const partners = [
  {
    name: 'Global Travel Services',
    description: 'Your trusted partner for planning unforgettable travel experiences.',
    url: '/globaltravelservices', // URL to redirect when clicked
  },
  // Add more partners as needed
];

const PartnerPage = () => {
  const navigate = useNavigate();

  const handleCardClick = (url) => {
    navigate(url); // Redirect to the specified URL
  };

  return (
    <Container>
      <Typography variant="h4" align="center" gutterBottom>
        Our Partners
      </Typography>
      <Grid container spacing={2} justifyContent="center" alignItems="center" style={{ minHeight: '70vh' }}>
        <Grid item xs={12}>
          <Card>
            <CardActionArea>
              <CardContent>
                <Typography variant="h5" align="center">
                  Coming Soon!
                </Typography>
                <Typography variant="body2" color="text.secondary" align="center">
                  We are working hard to bring you our partners. Stay tuned!
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default PartnerPage;
