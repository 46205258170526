import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Card, CardMedia, CardContent, Typography, Button, IconButton, Tooltip, Modal } from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import APIURL from '../config'; // Import the global API URL
import { useNavigate } from 'react-router-dom';

function ProductCard({ image, title, price, onAddToCart, showAlert, alertMessage, id, likes }) {
    const [liked, setLiked] = useState(false);
    const [likeCount, setLikeCount] = useState(likes);
    const [openZoom, setOpenZoom] = useState(false); // State for modal visibility
    const navigate = useNavigate();
    const userId = localStorage.getItem('userId');

    useEffect(() => {
        // Optionally, fetch the initial like status here
        // Example: check if the product is liked by this user and set the `liked` state accordingly
    }, [id, userId]);

    const handleLikeToggle = async () => {
        try {
            if (!userId) {
                navigate('/login');
                return;
            }

            const response = await axios.post(`${APIURL}/likes`, { userId, productId: id }, {
                headers: { 'Authorization': 'ubpatil' }
            });
            if (response.data.message === 'Product liked') {
                setLiked(true);
                setLikeCount(prevCount => prevCount + 1);
            } else if (response.data.message === 'Like removed') {
                setLiked(false);
                setLikeCount(prevCount => prevCount - 1);
            }
        } catch (error) {
            console.error('Error toggling like:', error);
        }
    };

    const handleZoomOpen = () => setOpenZoom(true);
    const handleZoomClose = () => setOpenZoom(false);

    return (
        <>
            <Card sx={{ maxWidth: 345, margin: '10px', borderRadius: 2 }}>
                <CardMedia
                    component="img"
                    height="400"
                    image={image}
                    alt={title}
                    onClick={handleZoomOpen} // Open zoom modal on click
                    style={{ cursor: 'pointer' }} // Change cursor to pointer for zoom indication
                />
                <CardContent>
                    <Typography variant="h6" component="div">
                        {title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {price}
                    </Typography>
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
                        <Button variant="contained" color="success" onClick={onAddToCart}>Add</Button>
                        <Tooltip title={liked ? 'Unlike' : 'Like'}>
                            <IconButton onClick={handleLikeToggle}>
                                {liked ? (
                                    <FavoriteIcon color="error" />
                                ) : (
                                    <FavoriteBorderIcon />
                                )}
                                {likeCount > 0 && (
                                    <span style={{ marginLeft: 4 }}>{likeCount}</span>
                                )}
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Contact Us on WhatsApp">
                            <IconButton 
                                component="a" 
                                href="https://wa.me/8261808996?text=Hello!%20I%20would%20like%20to%20get%20more%20information." 
                                target="_blank" 
                                rel="noopener noreferrer">
                                <WhatsAppIcon />
                            </IconButton>
                        </Tooltip>
                    </div>
                    {showAlert && (
                        <div className="alert-popup show">
                            {alertMessage}
                        </div>
                    )}
                </CardContent>
            </Card>

            {/* Modal for Image Zoom */}
            <Modal
                open={openZoom}
                onClose={handleZoomClose}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <img
                    src={image}
                    alt={title}
                    style={{
                        maxWidth: '90%',
                        maxHeight: '90%',
                        borderRadius: '8px',
                    }}
                />
            </Modal>
        </>
    );
}

export default ProductCard;
