import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Participants = () => {
    const navigate = useNavigate();
  
    useEffect(() => {
      navigate('/participants'); // Redirect to /participants
    }, [navigate]);
  
    return null; // Or some loading indicator if needed
  };
  
  export default Participants;
  