import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import { TextField, Button, Typography, Box } from '@mui/material';
import APIURL from '../config'; // Ensure this points to your backend URL 

function Signup() {
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState('');
  const [mobileError, setMobileError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [generalError, setGeneralError] = useState('');
  const navigate = useNavigate();

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const validateMobile = (mobile) => {
    const regex = /^\d{10}$/; // Adjust according to your mobile number format
    return regex.test(mobile);
  };

  const handleSignup = async (e) => {
    e.preventDefault();

    // Reset error messages
    setEmailError('');
    setMobileError('');
    setPasswordError('');
    setGeneralError('');

    // Check if fields are non-empty
    if (!email) {
      setEmailError('Email is required.');
      return;
    }

    if (!mobile) {
      setMobileError('Mobile number is required.');
      return;
    }

    if (!password) {
      setPasswordError('Password is required.');
      return;
    }

    // Validate email and mobile formats
    if (!validateEmail(email)) {
      setEmailError('Invalid email format.');
      return;
    }

    if (!validateMobile(mobile)) {
      setMobileError('Invalid mobile number. It should be 10 digits.');
      return;
    }

    try {
      // Proceed with signup if validation passes
      const signupResponse = await axios.post(`${APIURL}/signup`, {
        email,
        mobile,
        password
      }, {
        headers: { 'Authorization': 'ubpatil' }
      });

      if (signupResponse.status === 400 && signupResponse.data.message === 'Account already exists') {
        setGeneralError('Account already exists. Please login.');
      } else if (signupResponse.status === 400 && signupResponse.data.message === 'Account already exists with different password') {
        setGeneralError('Account already exists with a different password. Please login.');
      } else if (signupResponse.status === 201) {
        // Handle successful signup
        localStorage.setItem('userId', signupResponse.data.userId);
        localStorage.setItem('authToken', signupResponse.data.authToken);
        navigate('/');
      }
    } catch (error) {
      console.error('Signup error:', error.response ? error.response.data : error.message);
      setGeneralError(error.response ? error.response.data.message : 'Signup failed. Please try again.');
    }
  };

  return (
    <Box className="signup-container" sx={{ padding: 3, maxWidth: 400, margin: '0 auto' }}>
      <Typography variant="h4" gutterBottom>Signup</Typography>
      <Typography variant="body2" color="textSecondary" gutterBottom>
        Please do not enter fake mobile numbers or email IDs. We verify the data, and any fake information will be removed, along with the participant.
      </Typography>

      {generalError && (
        <Typography variant="body1" color="error" gutterBottom>
          {generalError}
        </Typography>
      )}
      {generalError && (
        <Link to="/login">
          <Button variant="contained" color="primary" sx={{ marginBottom: 2, width: '100%' }}>
            Login
          </Button>
        </Link>
      )}

      <form onSubmit={handleSignup}>
        <TextField
          fullWidth
          label="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          error={!!emailError}
          helperText={emailError}
          margin="normal"
          required
          InputLabelProps={{
            shrink: email.length > 0, // Ensure label shrinks when there is input
          }}
          sx={{ marginBottom: 2 }} // Spacing between fields
        />
        <TextField
          fullWidth
          label="Mobile"
          value={mobile}
          onChange={(e) => setMobile(e.target.value)}
          error={!!mobileError}
          helperText={mobileError}
          margin="normal"
          required
          InputLabelProps={{
            shrink: mobile.length > 0, // Ensure label shrinks when there is input
          }}
          sx={{ marginBottom: 2 }} // Spacing between fields
        />
        <TextField
          fullWidth
          label="Password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          error={!!passwordError}
          helperText={passwordError}
          margin="normal"
          required
          InputLabelProps={{
            shrink: password.length > 0, // Ensure label shrinks when there is input
          }}
          sx={{ marginBottom: 2 }} // Spacing between fields
        />
        <Button
          variant="contained"
          color="primary"
          type="submit"
          fullWidth
          sx={{ padding: '10px', marginTop: 2 }} // Add padding and margin
        >
          Signup
        </Button>
      </form>
    </Box>
  );
}

export default Signup;
