import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  TextField,
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  TableSortLabel,
} from '@mui/material';
import axios from 'axios';
import APIURL from '../../config'; // Import your global API URL

const ProductCategoryManagementPage = () => {
  const [categoryId, setCategoryId] = useState('');
  const [categoryName, setCategoryName] = useState('');
  const [categories, setCategories] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [editMode, setEditMode] = useState(false);
  const [currentCategoryId, setCurrentCategoryId] = useState('');

  const [sortBy, setSortBy] = useState('id');
  const [sortDirection, setSortDirection] = useState('asc');
  const [filterText, setFilterText] = useState('');

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const fetchProductCategories = async () => {
    try {
      const response = await axios.get(`${APIURL}/productcategoriesadmin`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'ubpatil', // Use correct token if needed
        },
      });
      setCategories(response.data);
    } catch (error) {
      console.error(error);
      setErrorMessage('Failed to load product categories');
    }
  };

  useEffect(() => {
    fetchProductCategories();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const categoryData = {
      id: categoryId,
      name: categoryName,
    };

    try {
      if (editMode) {
        const response = await axios.put(`${APIURL}/productcategoriesadmin/${currentCategoryId}`, categoryData, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'ubpatil',
          },
        });

        if (response.data.success) {
          setSuccessMessage('Product Category updated successfully!');
        }
      } else {
        const response = await axios.post(`${APIURL}/productcategoriesadmin`, categoryData, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'ubpatil',
          },
        });

        if (response.data.success) {
          setSuccessMessage('Product Category added successfully!');
        }
      }

      setCategoryId('');
      setCategoryName('');
      setEditMode(false);
      setCurrentCategoryId('');
      setErrorMessage('');
      fetchProductCategories();
    } catch (error) {
      console.error(error);
      setErrorMessage('An error occurred. Please try again later.');
    }
  };

  const handleEdit = (category) => {
    setCategoryId(category.id);
    setCategoryName(category.name);
    setEditMode(true);
    setCurrentCategoryId(category.id);
  };

  const handleDelete = async (categoryId) => {
    if (window.confirm('Are you sure you want to delete this category?')) {
      try {
        const response = await axios.delete(`${APIURL}/productcategoriesadmin/${categoryId}`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'ubpatil',
          },
        });

        if (response.data.success) {
          setSuccessMessage('Product Category deleted successfully!');
          fetchProductCategories();
        }
      } catch (error) {
        console.error(error);
        setErrorMessage('Failed to delete product category.');
      }
    }
  };

  const handleToggleActive = async (categoryId, isActive) => {
    try {
      const response = await axios.patch(`${APIURL}/productcategoriesadmin/${categoryId}`, { is_active: !isActive }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'ubpatil',
        },
      });

      if (response.data.success) {
        setSuccessMessage(`Product Category ${isActive ? 'deactivated' : 'activated'} successfully!`);
        fetchProductCategories();
      }
    } catch (error) {
      console.error(error);
      setErrorMessage('Failed to toggle product category status.');
    }
  };

  const handleSort = (column) => {
    const isAsc = sortBy === column && sortDirection === 'asc';
    setSortDirection(isAsc ? 'desc' : 'asc');
    setSortBy(column);
  };

  const filteredCategories = categories
    .filter((category) => category.name.toLowerCase().includes(filterText.toLowerCase()))
    .sort((a, b) => {
      if (sortDirection === 'asc') {
        return a[sortBy] > b[sortBy] ? 1 : -1;
      } else {
        return a[sortBy] < b[sortBy] ? 1 : -1;
      }
    });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Container maxWidth="lg">
      <Typography variant="h4" gutterBottom>
        Product Category Management
      </Typography>

      {errorMessage && <Typography color="error">{errorMessage}</Typography>}
      {successMessage && <Typography color="success">{successMessage}</Typography>}

      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Category Name"
              value={categoryName}
              onChange={(e) => setCategoryName(e.target.value)}
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button type="submit" variant="contained" color="primary" fullWidth>
              {editMode ? 'Update Category' : 'Add Category'}
            </Button>
          </Grid>
        </Grid>
      </form>

      <TextField
        label="Filter Categories"
        variant="outlined"
        fullWidth
        margin="normal"
        value={filterText}
        onChange={(e) => setFilterText(e.target.value)}
      />

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <TableSortLabel active={sortBy === 'id'} direction={sortDirection} onClick={() => handleSort('id')}>
                  ID
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel active={sortBy === 'name'} direction={sortDirection} onClick={() => handleSort('name')}>
                  Category Name
                </TableSortLabel>
              </TableCell>
              <TableCell>Action</TableCell>
              <TableCell>Activate/Deactivate</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredCategories.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((category) => (
              <TableRow key={category.id}>
                <TableCell>{category.id}</TableCell>
                <TableCell>{category.name}</TableCell>
                <TableCell>
                  <Button variant="contained" color="primary" onClick={() => handleEdit(category)}>
                    Edit
                  </Button>
                  <Button variant="contained" color="secondary" onClick={() => handleDelete(category.id)}>
                    Delete
                  </Button>
                </TableCell>
                <TableCell>
                  <Button variant="contained" color={category.is_active ? 'error' : 'success'} onClick={() => handleToggleActive(category.id, category.is_active)}>
                    {category.is_active ? 'Deactivate' : 'Activate'}
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={filteredCategories.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Container>
  );
};

export default ProductCategoryManagementPage;
