import React, { useEffect } from 'react'; // Import useEffect
import { Container, Grid, Card, CardMedia, CardContent, Typography } from '@mui/material';
import winner1 from '../Images/winner1.jpg'; // Replace with actual winner images
import winner2 from '../Images/winner2.jpg'; // Replace with actual winner images
import winner3 from '../Images/winner3.jpg'; // Replace with actual winner images
import winner4 from '../Images/winner4.jpg';
import winner5 from '../Images/winner5.jpg';  
import './WinnerPage.css'; // Import your CSS file

const winners = [
  {
    id: 1,
    name: 'Rudra Sandeep Pawar',
    image: winner1,
    description: 'Badlapur.',
  },
  {
    id: 2,
    name: 'Tejesh Dhande',
    image: winner2,
    description: 'Pune.',
  }, 
  {
    id: 3,
    name: 'Siddhesh Suresh Deshmukh',
    image: winner3,
    description: 'Badlapur.',
  }, 
  {
    id: 4,
    name: 'Punam Nehete',
    image: winner4,
    description: 'Pune.',
  }, 
  {
    id: 5,
    name: 'Likhita Swapnil Talele',
    image: winner5,
    description: 'Nasik.',
  }, 
];

const WinnerPage = () => {
  // Use useEffect to scroll to the top when the component mounts
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page
  }, []); // Empty dependency array means this runs once when the component mounts

  return (
    <Container className="container">
      <Typography variant="h4" align="center" className="title">
        Ganpati Bappa Contest 2024 Winners
      </Typography>
      <Typography variant="body1" align="center" className="description" paragraph>
        Welcome to the Ganpati Bappa Contest 2024! This initiative by Yuvalink aims to bring together the community, fostering creativity and camaraderie among participants. Join us in celebrating the spirit of togetherness and challenge as we showcase the talents of common people. Congratulations to our winners!
      </Typography>
      <Grid container spacing={2}>
        {winners.map((winner) => (
          <Grid item xs={12} sm={6} md={4} key={winner.id} className="winnerCard">
            <Card>
              <CardMedia
                component="img"
                className="winnerImage"
                image={winner.image}
                alt={winner.name}
              />
              <CardContent>
                <Typography variant="h5">{winner.name}</Typography>
                <Typography variant="body2" color="text.secondary">
                  {winner.description}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default WinnerPage;
