import React from 'react';
import {
  Container,
  Typography,
  Box,
  Paper,
  Grid,
  Avatar,
  Divider,
  Card,
  CardContent,
} from '@mui/material';
import { styled } from '@mui/system';
import ratantataImage from '../Images/ratantata.jpg'; // Import the image

const TributeImage = styled(Avatar)({
  width: 200,
  height: 200,
  marginBottom: '20px',
});

const CondolencePage = () => {
  return (
    <Container maxWidth="md">
      <Paper elevation={3} sx={{ padding: '20px', marginTop: '20px' }}>
        <Typography variant="h4" color="error" gutterBottom align="center">
          भावपूर्ण श्रद्धांजली
        </Typography>
        <Typography variant="h5" align="center">
          रतन टाटा यांना आदरांजली
        </Typography>
        <Typography variant="subtitle1" align="center" gutterBottom>
          १९३७ - २०२४
        </Typography>

        <Divider sx={{ marginBottom: '20px' }} />

        <Grid container justifyContent="center">
          <TributeImage
            alt="Ratan Tata"
            src={ratantataImage} // Use imported image
          />
        </Grid>

        <Typography variant="body1" paragraph>
          "खूप दुःखाने आम्ही भारतीय औद्योगिक क्षेत्रातील एक महान व्यावसायिकाला अंतिम सलाम करत आहोत. रतन टाटा यांचे संपूर्ण जीवन भारताच्या व्यावसायिक क्षेत्राला उंचावर नेण्यासाठी समर्पित होते. त्याचबरोबर त्यांनी समाजाच्या कल्याणासाठी असंख्य कारणांसाठी योगदान दिले. त्यांचे औद्योगिक वाढ आणि परोपकाराचे योगदान सदैव अविस्मरणीय राहील."
        </Typography>

        <Card sx={{ marginBottom: '20px' }}>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              जीवनचरित्र
            </Typography>
            <Typography variant="body2">
              रतन टाटा यांचा जन्म २८ डिसेंबर १९३७ रोजी मुंबई मध्ये झाला. ते टाटा समूहाचे संस्थापक जमशेटजी टाटा यांचे पणतू आहेत. १९९१ मध्ये त्यांनी टाटा समूहाचे अध्यक्षपद स्वीकारले आणि अनेक जागतिक अधिग्रहणांसह कंपनीला नव्या उंचीवर नेले, ज्यामध्ये जग्वार लँड रोव्हर आणि टेटलीचा समावेश आहे. त्यांच्या नम्रतेमुळे, परोपकारामुळे आणि दूरदृष्टीमुळे रतन टाटा यांची ओळख जागतिक व्यावसायिक जगतात कायमस्वरूपी राहिली आहे.
            </Typography>
          </CardContent>
        </Card>

        <Card sx={{ marginBottom: '20px' }}>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              संघर्ष आणि यश
            </Typography>
            <Typography variant="body2">
              जरी ते एका व्यावसायिक साम्राज्यात जन्मलेले असले तरी रतन टाटा यांना त्यांच्या करिअरमध्ये अनेक आव्हाने सामोरे जावे लागले. त्यांनी स्वतःची कर्तबगारी सिद्ध केली आणि टाटा नॅनो, जगातील सर्वात स्वस्त कार सुरू करताना त्यांना तीव्र विरोध झाला. तथापि, त्यांच्या दृढनिश्चयी आणि दूरदृष्टीमुळे टाटा समूहाची जागतिक पातळीवर उपस्थिती वाढली आणि तो सर्वात मोठा आणि आदरणीय बहुराष्ट्रीय समूहांपैकी एक बनला.
            </Typography>
          </CardContent>
        </Card>

        <Card sx={{ marginBottom: '20px' }}>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              परोपकार
            </Typography>
            <Typography variant="body2">
              व्यवसायाबाहेर, रतन टाटा यांना त्यांच्या परोपकारी कामासाठी मोठ्या प्रमाणावर ओळखले जाते. टाटा ट्रस्ट्सद्वारे, त्यांनी भारतातील शिक्षण, आरोग्यसेवा आणि ग्रामीण विकासासाठी योगदान दिले आहे. समाजाच्या कल्याणासाठी त्यांच्या वैयक्तिक समर्पणामुळे त्यांच्या देशासाठी आणि त्याच्या लोकांसाठी असलेली जबाबदारी स्पष्ट होते.
            </Typography>
          </CardContent>
        </Card>

        <Box sx={{ padding: '20px', backgroundColor: '#f3f3f3', marginBottom: '20px' }}>
          <Typography variant="h6" gutterBottom align="center">
            "मी योग्य निर्णय घेतो असं मला वाटत नाही. मी निर्णय घेतो आणि मग त्यांना योग्य बनवतो."
          </Typography>
          <Typography variant="body2" align="center">
            — रतन टाटा
          </Typography>
        </Box>

        <Divider sx={{ marginBottom: '20px' }} />

        <Typography variant="body1" paragraph>
          भावपूर्ण श्रद्धांजली: रतन टाटा यांनी त्यांच्या जीवनातील प्रत्येक पैलूमध्ये जे त्याग, योगदान आणि समर्पण दाखवले आहे, ते सदैव आम्हाला प्रेरित करत राहील. त्यांनी केवळ व्यवसायाच्या क्षेत्रात प्रगती केली नाही, तर समाजालाही अपार योगदान दिले. त्यांच्या जाण्याने एक युगाचा अंत झाला आहे.
        </Typography>
      </Paper>
    </Container>
  );
};

export default CondolencePage;
