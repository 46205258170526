import React, { useState, useRef } from 'react';
import axios from 'axios';
import {
  TextField,
  Button,
  Typography,
  CircularProgress,
  Snackbar,
  Container,
  Paper,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  createTheme,
  ThemeProvider,
  CssBaseline,
  Box,
} from '@mui/material';
import APIURL from '../config';
import styles from './UploadPage.module.css';

const theme = createTheme({
  palette: {
    primary: { main: '#1976d2' },
    secondary: { main: '#f50057' },
    background: { default: '#f5f5f5' },
  },
});

function UploadPage() {
  const [name, setName] = useState('');
  const [mobile, setMobile] = useState('');
  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [mobileError, setMobileError] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const mobileInputRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [selectedDay, setSelectedDay] = useState('');
  const videoRef = useRef(null);
  const [isCameraOn, setIsCameraOn] = useState(false);
  const [isBackCamera, setIsBackCamera] = useState(false);

  const validateMobile = (mobile) => {
    const mobileRegex = /^[6-9]\d{9}$/;
    return mobileRegex.test(mobile);
  };

  const handleCapture = async () => {
    const video = videoRef.current;
    const canvas = document.createElement('canvas');
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    const context = canvas.getContext('2d');
    context.drawImage(video, 0, 0, canvas.width, canvas.height);
    const dataUrl = canvas.toDataURL('image/jpeg');
    setImagePreview(dataUrl);
    setImage(dataUrl); 
    setIsCameraOn(false); 
  };

  const handleStartCamera = async (useBackCamera) => {
    setIsCameraOn(true);
    setIsBackCamera(useBackCamera);
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: { facingMode: useBackCamera ? 'environment' : 'user' },
      });
      videoRef.current.srcObject = stream;
      videoRef.current.play();
    } catch (error) {
      console.error('Error accessing the camera:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateMobile(mobile)) {
      setMobileError('Invalid mobile number. Please enter a valid 10-digit number starting with 6-9.');
      mobileInputRef.current.focus();
      return;
    } else {
      setMobileError('');
    }

    if (!isConfirmed) {
      alert('Please confirm the upload before submitting.');
      return;
    }

    const formData = new FormData();
    formData.append('name', name);
    formData.append('mobile', mobile);
    formData.append('day', selectedDay);
    function dataURLtoFile(dataurl, filename) {
      const arr = dataurl.split(',');
      const mime = arr[0].match(/:(.*?);/)[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    }

    const file = dataURLtoFile(imagePreview, 'captured-image.jpg');
    formData.append('image', file);

    try {
      setLoading(true);
      await axios.post(`${APIURL}/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': 'ubpatil',
        },
      });

      setIsSubmitted(true);
      setShowPopup(true);
      setSnackbarOpen(true);

      setTimeout(() => {
        window.location.href = 'https://yuvalink.com/participantsPage';
      }, 6000);
    } catch (error) {
      console.error('Failed to upload:', error);
      alert(error.response?.data?.message || 'Upload failed. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleConfirm = () => {
    setIsConfirmed(true);
    alert('Upload confirmed. You can now submit.');
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
        setImage(file);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container maxWidth="sm" sx={{ marginTop: 4 }}>
        <Paper elevation={3} sx={{ padding: 3, backgroundColor: 'white', borderRadius: 2 }}>
          <Typography variant="h4" gutterBottom align="center" color="primary">
            Online Rangoli Competition Navratri Festival October 2024
          </Typography>
          <Typography variant="body1" paragraph align="center">
            Welcome to the Online Rangoli Competition! Capture your Rangoli or upload an image to participate.
          </Typography>

          <form onSubmit={handleSubmit}>
            <TextField
              label="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              fullWidth
              margin="normal"
              variant="outlined"
              className={styles.inputField}
            />

            <Typography variant="body2" color="error">{mobileError}</Typography>

            <TextField
              label="Mobile"
              type="tel"
              inputRef={mobileInputRef}
              value={mobile}
              onChange={(e) => setMobile(e.target.value)}
              required
              fullWidth
              margin="normal"
              variant="outlined"
              error={Boolean(mobileError)}
            />

            <FormControl fullWidth margin="normal" required>
              <InputLabel id="day-select-label">Select Day</InputLabel>
              <Select
                labelId="day-select-label"
                value={selectedDay}
                onChange={(e) => setSelectedDay(e.target.value)}
                label="Select Day"
              >
                {[...Array(9).keys()].map((day) => (
                  <MenuItem key={day + 1} value={day + 1}>
                    Day {day + 1}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Button
              variant="contained"
              component="label"
              sx={{ width: '100%', mt: 2 }}
            >
              Upload Image
              <input
                type="file"
                accept="image/*"
                hidden
                onChange={handleFileUpload}
              />
            </Button>

            
            <Button
              variant="contained"
              sx={{ backgroundColor: '#4CAF50', color: 'white', width: '100%', mt: 2 }}
              onClick={() => handleStartCamera(true)} // Back camera
            >
              Start Back Camera
            </Button>

            {isCameraOn && (
              <div>
                <video ref={videoRef} style={{ width: '100%', height: 'auto', borderRadius: '8px' }} />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleCapture}
                  sx={{ mt: 2, width: '100%' }}
                >
                  Capture Image
                </Button>
              </div>
            )}

            {imagePreview && (
              <Box sx={{ textAlign: 'center', mt: 2 }}>
                <Typography variant="body2" color="textSecondary">Image Preview:</Typography>
                <img src={imagePreview} alt="Captured" style={{ maxWidth: '100%', height: 'auto', borderRadius: '8px' }} />
              </Box>
            )}

            {!isConfirmed && (
              <Button
                variant="contained"
                sx={{ backgroundColor: '#4CAF50', color: 'white', mt: 2 }}
                onClick={handleConfirm}
                disabled={!image}
                fullWidth
              >
                Confirm Upload
              </Button>
            )}

            <Button
              variant="contained"
              color="secondary"
              type="submit"
              disabled={!isConfirmed}
              fullWidth
              sx={{ mt: 2 }}
            >
              {loading ? <CircularProgress size={24} /> : 'Submit'}
            </Button>
          </form>

          {showPopup && (
            <div className="popup">
              <div className="popup-content">
                <h2>Thank you for participating!</h2>
                <p>We will approve your submission shortly after validation.</p>
                <a href="https://yuvalink.com/participantsPage">View Participants</a>
              </div>
            </div>
          )}

          <Snackbar
            open={snackbarOpen}
            autoHideDuration={6000}
            onClose={handleSnackbarClose}
            message="Upload successful!"
          />
        </Paper>
      </Container>
    </ThemeProvider>
  );
}

export default UploadPage;
