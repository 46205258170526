import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import { Grid, Card, CardMedia, CardContent, Typography, Button, Box, IconButton, MenuItem, Select } from '@mui/material';
import ShareIcon from '@mui/icons-material/Share';
import FavoriteIcon from '@mui/icons-material/Favorite';

import API_URL from '../config';

function ParticipantsPage() {
  const [participants, setParticipants] = useState([]);
  const [totalParticipants, setTotalParticipants] = useState(0);
  const [userId, setUserId] = useState(localStorage.getItem('userId'));
  const [currentPage, setCurrentPage] = useState(1);
  const [participantsPerPage] = useState(20);
  const [zoomedImage, setZoomedImage] = useState(null);
  const [nameFilter, setNameFilter] = useState('');
  const [dayFilter, setDayFilter] = useState(''); // New state for day filter
  const [countdown, setCountdown] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  // Extract the participantId from query parameters
  const queryParams = new URLSearchParams(location.search);
  const participantId = queryParams.get('participantId');

  // Set the competition end date (e.g., October 13, 2024)
  const competitionEndDate = new Date('October 13, 2024 23:59:59').getTime();

  useEffect(() => {
    // Fetch participants
    const fetchParticipants = () => {
      axios
        .get(`${API_URL}/participants?page=${currentPage}&limit=${participantsPerPage}&participantId=${participantId || ''}&name=${nameFilter}&day=${dayFilter}`, {
          headers: {
            Authorization: 'ubpatil',
          },
        })
        .then((response) => {
          setParticipants(response.data.participants || []);
          setTotalParticipants(response.data.totalParticipants || 0);
        })
        .catch((error) => console.error('Error fetching participants:', error));
    };

    fetchParticipants();
  }, [currentPage, participantsPerPage, location.search, nameFilter, participantId, dayFilter]); // Add dayFilter to dependencies

  useEffect(() => {
    const updateCountdown = () => {
      const now = new Date().getTime();
      const distance = competitionEndDate - now;

      if (distance < 0) {
        setCountdown('Competition has ended');
        return;
      }

      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      setCountdown(`${days}d ${hours}h ${minutes}m ${seconds}s`);
    };

    const countdownInterval = setInterval(updateCountdown, 1000);

    return () => clearInterval(countdownInterval);
  }, []);

  const handleFilterChange = (e) => {
    setNameFilter(e.target.value);
    setCurrentPage(1); // Reset to the first page when filter changes
  };

  const handleDayChange = (e) => {
    setDayFilter(e.target.value);
    setCurrentPage(1); // Reset to the first page when day filter changes
  };

  const handleLikeToggle = async (participantId) => {
    if (!userId) {
      navigate('/signup');
      return;
    }

    try {
      const response = await axios.post(
        `${API_URL}/like/${participantId}`,
        {},
        {
          headers: {
            Authorization: 'ubpatil',
            'user-id': userId,
          },
        }
      );

      if (response.data.message === 'Like recorded') {
        setParticipants((prevParticipants) =>
          prevParticipants.map((participant) =>
            participant.id === participantId
              ? { ...participant, like_count: participant.like_count + 1, liked: true }
              : participant
          )
        );
      } else if (response.data.message === 'Like removed') {
        setParticipants((prevParticipants) =>
          prevParticipants.map((participant) =>
            participant.id === participantId
              ? { ...participant, like_count: participant.like_count - 1, liked: false }
              : participant
          )
        );
      }
    } catch (error) {
      console.error('Error toggling like:', error);
    }
  };

  const handleImageClick = (image) => {
    setZoomedImage(image);
  };

  const closeZoomedImage = () => {
    setZoomedImage(null);
  };

  const totalPages = Math.ceil(totalParticipants / participantsPerPage);

  const generateWhatsAppLink = (participant) => {
    const message = `Hi! I need your help to support ${participant.name} in the Navratri Rangoli Competition. Please like their profile using this link:\n\n${window.location.href}?participantId=${participant.id}\n\nJay Mata Di! Your support means a lot and could help them win the top spot! Thank you!\n\nSave this page as a bookmark to stay updated.`;

    return `https://wa.me/?text=${encodeURIComponent(message)}`;
  };

  return (
    <div style={{ padding: '20px', textAlign: 'center' }}>
      <h1>Online Rangoli Competition Navratri Festival October 2024</h1>
      <p>This competition is part of the Navratri festival and runs for 9 days. </p>
       <p> At the end of the competition, we will count all the likes received each day and announce
         the winners from the top 5 participants.</p> Show your support by liking your favorite entries!
      <div style={{ marginBottom: '20px' }}>
        <h3>Save to Bookmark</h3>
        <p>To stay updated and easily access this page, save it as a bookmark in your browser.</p>
      </div>

      <div style={{ marginBottom: '20px' }}>
        <h3>Time Left Until Competition Ends:</h3>
        <p style={{ fontSize: '24px', fontWeight: 'bold', color: 'red' }}>{countdown}</p>
      </div>

      <p style={{ color: 'red' }}>
        Please do not enter fake mobile numbers or email IDs. We verify the data, and any fake information will be removed, along with the participant.
      </p>

      <input
        type="text"
        placeholder="Filter by name..."
        value={nameFilter}
        onChange={handleFilterChange}
        style={{
          padding: '10px',
          width: '100%',
          marginBottom: '20px',
          fontSize: '16px',
        }}
      />

      {/* Day filter dropdown */}
      <Select
        value={dayFilter}
        onChange={handleDayChange}
        displayEmpty
        style={{ width: '100%', marginBottom: '20px' }}
      >
        <MenuItem value="">
          <em>All Days</em>
        </MenuItem>
        {[...Array(9).keys()].map((day) => (
          <MenuItem key={day + 1} value={day + 1}>
            Day {day + 1}
          </MenuItem>
        ))}
      </Select>

      {participantId && (
        <a href="/participantsPage" target="_top" rel="noopener noreferrer" style={{ color: '#0066cc', marginBottom: '20px', display: 'block' }}>
          View all participants
        </a>
      )}

      <Grid container spacing={2}>
        {participants.length > 0 ? (
          participants.map((p) => (
            <Grid item xs={12} sm={6} md={4} key={p.id}>
              <Card>
                <CardMedia
                  component="img"
                  image={`${API_URL}/upload/${p.image}`}
                  alt={p.name}
                  onClick={() => handleImageClick(`${API_URL}/upload/${p.image}`)}
                  style={{ cursor: 'pointer', height: '200px', objectFit: 'cover' }}
                />
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    Day {p.day}: {p.name}
                  </Typography>
                  <Box display="flex" flexDirection="column" alignItems="center" marginTop="10px">
                    <Typography variant="body2" color="textSecondary">
                      Likes: {p.like_count}
                    </Typography>
                    <IconButton onClick={() => handleLikeToggle(p.id)} color={p.liked ? 'primary' : 'default'}>
                      <FavoriteIcon />
                    </IconButton>
                    <Button
                      variant="contained"
                      color="success"
                      onClick={() => window.open(generateWhatsAppLink(p), '_blank')}
                      style={{
                        backgroundColor: '#25D366', // WhatsApp green
                        color: 'white',
                        fontWeight: 'bold',
                        padding: '10px 20px',
                        textTransform: 'none',
                      }}
                    >
                      Share on WhatsApp
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))
        ) : (
          <Typography variant="h6">No participants found.</Typography>
        )}
      </Grid>

      <div style={{ marginTop: '20px' }}>
        <Button
          disabled={currentPage === 1}
          onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
        >
          Previous
        </Button>
        <span style={{ margin: '0 10px' }}>
          Page {currentPage} of {totalPages}
        </span>
        <Button
          disabled={currentPage === totalPages}
          onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
        >
          Next
        </Button>
      </div>

      {zoomedImage && (
        <div onClick={closeZoomedImage} style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'rgba(0,0,0,0.7)', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <img src={zoomedImage} alt="Zoomed" style={{ maxWidth: '90%', maxHeight: '90%' }} />
        </div>
      )}
    </div>
  );
}

export default ParticipantsPage;
