import React from 'react';
import { Container, Typography, Grid, Card, CardContent, CardMedia } from '@mui/material';

// Import images or use public URL paths
import vacationImage from '../Images/Vacation.png';
import tourImage from '../Images/tour.png';
import businessImage from '../Images/business.png';
import adventureImage from '../Images/adventure.png';
import cruiseImage from '../Images/cruise.png';

function GlobalTravelServices() {
  const travelServices = [
    {
      title: 'Vacation Packages',
      description: 'Explore our all-inclusive vacation packages to top destinations around the world.',
      image: vacationImage, // Use imported image
    },
    {
      title: 'Guided Tours',
      description: 'Join our expert-guided tours to discover hidden gems in popular tourist spots.',
      image: tourImage, // Use imported image
    },
    {
      title: 'Business Travel',
      description: 'Customized business travel services to ensure a seamless and stress-free experience.',
      image: businessImage, // Use imported image
    },
    {
      title: 'Adventure Tours',
      description: 'Adrenaline-packed adventure tours for thrill-seekers in exotic locations.',
      image: adventureImage, // Use imported image
    },
    {
      title: 'Cruises',
      description: 'Luxury cruises to exotic destinations with all the amenities you need.',
      image: cruiseImage, // Use imported image
    },
  ];

  return (
    <Container maxWidth="lg" style={{ padding: '20px 0', marginBottom: '50px' }}> {/* Added marginBottom */}
      <Typography variant="h4" align="center" gutterBottom>
        Global Travel Services
      </Typography>
      <Typography variant="body1" align="center" paragraph>
        Experience the world like never before with our top-rated travel services. Whether you're looking for an exotic vacation, business travel solutions, or an adventurous escape, we've got you covered.
      </Typography>

      <Grid container spacing={4}>
        {travelServices.map((service, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card style={{ height: '100%' }}> {/* Fixed height to 100% for proper scaling */}
              <CardMedia
                component="img"
                height="400"  // Adjusted height for images
                image={service.image}
                alt={service.title}
                style={{ objectFit: 'cover' }} // Ensures proper image fitting
              />
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  {service.title}
                </Typography>
                <Typography variant="body2" color="textSecondary" paragraph>
                  {service.description}
                </Typography> 
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}

export default GlobalTravelServices;
