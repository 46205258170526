import React, { useState, useEffect } from 'react';
import axios from 'axios'; 
import APIURL from '../../config.js';
import ImageModal from './ImageModal'; // Import the ImageModal component if needed
import { 
    Box, 
    Table, 
    TableBody, 
    TableCell, 
    TableContainer, 
    TableHead, 
    TableRow, 
    Button, 
    TextField, 
    Typography 
} from '@mui/material';

function LeadDetails() {
    const [leads, setLeads] = useState([]);
    const [filteredLeads, setFilteredLeads] = useState([]);
    const [filters, setFilters] = useState({ search: '' });
    const [sortOrder, setSortOrder] = useState('asc');
    const [modalImage, setModalImage] = useState(null); // State for modal image
    const [modalOpen, setModalOpen] = useState(false); // State for modal visibility
    const [expandedLeads, setExpandedLeads] = useState(new Set()); // State for expanded rows

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${APIURL}/leads`, {
                    headers: { 'Authorization': 'ubpatil' }
                });
                setLeads(response.data);
            } catch (error) {
                console.error('Error fetching leads:', error);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        const applyFilters = () => {
            let filtered = leads;

            if (filters.search) {
                filtered = filtered.filter(lead =>
                    lead.name.toLowerCase().includes(filters.search.toLowerCase()) ||
                    lead.mobile.includes(filters.search) || 
                    lead.email.toLowerCase().includes(filters.search)
                );
            }

            setFilteredLeads(filtered);
        };

        applyFilters();
    }, [leads, filters]);

    const handleSort = () => {
        const sortedLeads = [...filteredLeads].sort((a, b) => {
            if (sortOrder === 'asc') {
                return new Date(a.created_at) - new Date(b.created_at);
            } else {
                return new Date(b.created_at) - new Date(a.created_at);
            }
        });
        setFilteredLeads(sortedLeads);
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    };

    const toggleExpand = (leadId) => {
        setExpandedLeads(prev => {
            const newSet = new Set(prev);
            if (newSet.has(leadId)) {
                newSet.delete(leadId);
            } else {
                newSet.add(leadId);
            }
            return newSet;
        });
    };

    const openModal = (imageSrc) => {
        setModalImage(imageSrc);
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
        setModalImage(null);
    };

    return (
        <Box className="lead-details-container" sx={{ padding: 2 }}>
            <Box className="filtersLead" sx={{ marginBottom: 2 }}>
                <TextField
                    variant="outlined"
                    placeholder="Search by name, mobile, or email..."
                    onChange={(e) => setFilters(prev => ({ ...prev, search: e.target.value }))}
                    fullWidth
                />
                <Button variant="contained" onClick={handleSort} sx={{ marginLeft: 1 }}>
                    Sort by Date ({sortOrder === 'asc' ? 'Ascending' : 'Descending'})
                </Button>
            </Box>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Lead ID</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Mobile</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>Location</TableCell>
                            <TableCell>Locality</TableCell>
                            <TableCell>Description</TableCell>
                            <TableCell>Business ID</TableCell>
                            <TableCell>Category ID</TableCell>
                            <TableCell>Category Name</TableCell>
                            <TableCell>Created At</TableCell>
                            <TableCell>Updated At</TableCell>
                            <TableCell>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredLeads.map(lead => (
                            <React.Fragment key={lead.id}>
                                <TableRow>
                                    <TableCell>{lead.id}</TableCell>
                                    <TableCell>{lead.name}</TableCell>
                                    <TableCell>{lead.mobile}</TableCell>
                                    <TableCell>{lead.email}</TableCell>
                                    <TableCell>{lead.location}</TableCell>
                                    <TableCell>{lead.locality}</TableCell>
                                    <TableCell>{lead.description}</TableCell>
                                    <TableCell>{lead.business_id}</TableCell>
                                    <TableCell>{lead.category_id}</TableCell>
                                    <TableCell>{lead.category_name}</TableCell>
                                    <TableCell>{new Date(lead.created_at).toLocaleString()}</TableCell>
                                    <TableCell>{new Date(lead.updated_at).toLocaleString()}</TableCell>
                                    <TableCell>
                                        <Button variant="outlined" onClick={() => toggleExpand(lead.id)}>
                                            {expandedLeads.has(lead.id) ? 'Collapse' : 'Expand'}
                                        </Button>
                                    </TableCell>
                                </TableRow>
                                {expandedLeads.has(lead.id) && (
                                    <TableRow>
                                        <TableCell colSpan={13}>
                                            <Typography variant="body2">
                                                <strong>Lead Details:</strong>
                                                <div>{lead.description}</div>
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                )}
                            </React.Fragment>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <ImageModal
                imageSrc={modalImage}
                isOpen={modalOpen}
                onClose={closeModal}
            />
        </Box>
    );
}

export default LeadDetails;
