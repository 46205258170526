import React, { useState } from 'react'; 
import { Typography, Box, Dialog, DialogContent, IconButton, Paper } from '@mui/material'; 
import CloseIcon from '@mui/icons-material/Close'; // Close icon for the modal
import '../App.css';
import UmeshPhoto from '../Images/Photo.jpg'; // Import the image

function AboutUs() {
  const [open, setOpen] = useState(false); // State to control the modal

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box className="about-us" sx={{ padding: 3, backgroundColor: '#f9f9f9', borderRadius: '8px' }}>
      <Typography variant="h4" gutterBottom color="primary.main" sx={{ textAlign: 'center' }}>
        About Us
      </Typography>

      <Paper elevation={3} sx={{ padding: 2, marginBottom: 3 }}>
        <Typography variant="body1" paragraph>
          Yuvalink is an online platform for community and shopping engagement. Buy products and connect with people to share opportunities, ideas, and experiences.
        </Typography>
        <Typography variant="body1" paragraph>
          We offer a wide selection of products available for online purchase in the Badlapur area and global location, including Ganesh Bappa Moraya Murti bookings. Our mission is to promote sustainable and environmentally friendly practices during festivals, ensuring our offerings reflect these values.
        </Typography>
        <Typography variant="body1" paragraph>
          Our products are crafted from natural materials that are safe for the environment. We provide a diverse range of items suitable for various occasions, all designed with care and quality in mind.
        </Typography>
        <Typography variant="body1" paragraph>
          Additionally, we organize various competitions to encourage community participation and raise social awareness. Our goal is to foster a spirit of togetherness and creativity within our community while promoting important social causes.
        </Typography>
      </Paper>

      {/* Highlighting Umesh Patil's Section with Photo */}
      <Typography variant="body1" paragraph sx={{ fontWeight: 'bold', color: 'primary.main' }}>
        Our company is owned by Umesh Patil, who has over 15 years of experience in software development. His aim is to establish a robust online business that provides opportunities for small-scale entrepreneurs, empowering them to thrive in the digital marketplace.
      </Typography>
      
      {/* New Section: Who is Umesh Patil */}
      <Box sx={{ marginTop: 4, display: 'flex', alignItems: 'center' }}>
       
        <Box>
          <Typography variant="h5" gutterBottom>Who is Umesh Patil?</Typography>
          <img 
          src={UmeshPhoto} // Use the imported image
          alt="Umesh Patil" 
          style={{ width: '256px', height: '256px', borderRadius: '8px', marginRight: '16px', cursor: 'pointer' }} // Set image size and style
          onClick={handleClickOpen} // Open modal on click
        />
          <Typography variant="body1" paragraph>
            Umesh Patil is from a small village called Achegaon in Bhusaval Taluka, Jalgaon district, Maharashtra. Growing up in a farmer's family, he learned the values of hard work and perseverance from an early age. In 2011, Umesh started his professional journey as a junior developer in Mumbai, marking the beginning of his career in software development.
          </Typography>
          <Typography variant="body1" paragraph>
            Through dedication and hard work, he progressed rapidly, eventually working with multiple clients, including a prominent Austrian multinational company and a US-based client. This experience opened his eyes to the potential of using his skills to assist small business owners and contribute to his community. Driven by a desire to make a positive impact, Umesh decided to extend his help to farmers and local entrepreneurs.
          </Typography>
          <Typography variant="body1" paragraph>
            In 2024, he founded Yuvalink, a platform aimed at empowering small-scale entrepreneurs by providing them with opportunities in the digital marketplace. Umesh's vision is to create a supportive ecosystem that fosters growth and innovation among local businesses while promoting sustainable practices.
          </Typography>
        </Box>
      </Box>

      <Typography variant="body1" paragraph>
        Join us in our journey to support local businesses and promote a sustainable future. For more information or to place an order, please contact us.
      </Typography>

      <Box sx={{ marginTop: 4 }}>
        <Typography variant="h5" gutterBottom>Ganpati Bappa Contest 2024</Typography>
        <Typography variant="body1" paragraph>
          The Ganpati Bappa Contest 2024 has concluded, and we would like to congratulate all participants for their amazing contributions! 
        </Typography>
        <Typography variant="body1" paragraph>
          Winners have been declared, and we thank everyone for their enthusiastic participation. As they say, "Victory is always possible for those who refuse to stop fighting."
        </Typography>
      </Box>

      <Box sx={{ marginTop: 4 }}>
        <Typography variant="h5" gutterBottom>Navratri Rangoli Contest 2024</Typography>
        <Typography variant="body1" paragraph>
          We are excited to announce that the Navratri Rangoli Spardha has now started! Join us in celebrating this beautiful festival by showcasing your creativity and skills.
        </Typography>
        <Typography variant="body1" paragraph>
          Participate to win exciting prizes and have fun during this vibrant festival! As Albert Einstein once said, "Creativity is contagious, pass it on."
        </Typography>
      </Box>

      {/* Modal for Full Image View */}
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogContent sx={{ position: 'relative', display: 'flex', justifyContent: 'center' }}>
          <IconButton 
            onClick={handleClose} 
            sx={{ position: 'absolute', top: 8, right: 8, color: 'primary.main' }} 
          >
            <CloseIcon />
          </IconButton>
          <img 
            src={UmeshPhoto} // Use the imported image in modal
            alt="Umesh Patil" 
            style={{ width: '100%', height: 'auto', borderRadius: '8px' }} // Full width image in modal
          />
        </DialogContent>
      </Dialog>
    </Box>
  );
}

export default AboutUs; 
