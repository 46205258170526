import React from 'react';
import { Container, Grid, Card, CardMedia, CardContent, Typography, CardActionArea } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import shopingimage from '../Images/directoryMenu.png'; // Adjust paths accordingly
import contest from '../Images/contest.png';
import peoples from '../Images/peoples.png';
import partnersImage from '../Images/Partners.png'; // Import the partner image

const services = [
  {
    id: 1,
    title: 'Business Directory',
    description: 'Empower local and global  businesses directory by selling a variety of products through our platform. Connect with local vendors and shop from different categories.',
    image: shopingimage,
    url: '/businessdirectory', // URL to redirect when clicked
  },
  {
    id: 2,
    title: 'Community Contests',
    description: 'Join exciting community contests that bring people together. Participate in events that foster creativity and unity.',
    image: contest,
    url: '/contest', // URL to redirect when clicked
  },
  {
    id: 3,
    title: 'Social Initiatives',
    description: 'Contribute to social causes through online signatures and support efforts that make a difference in the lives of common people.',
    image: peoples,
    url: '/socialinitiatives', // URL to redirect when clicked
  },
  {
    id: 4,
    title: 'Partner with Us',
    description: 'Become a trusted partner and collaborate with us to provide exclusive deals and offers.',
    image: partnersImage, // Use partner image
    url: '/partners', // URL to redirect when clicked
  },
];

const ServicePage = () => {
  const navigate = useNavigate();

  const handleCardClick = (url) => {
    navigate(url); // Redirect to the specified URL
  };

  return (
    <Container>
      <Typography variant="h4" align="center" gutterBottom>
        Our Services
      </Typography>
      <Grid container spacing={2}>
        {services.map((service) => (
          <Grid item xs={12} sm={6} md={4} key={service.id}>
            <Card>
              <CardActionArea onClick={() => handleCardClick(service.url)}>
                <CardMedia
                  component="img"
                  height="70%"
                  image={service.image}
                  alt={service.title}
                />
                <CardContent>
                  <Typography variant="h5">{service.title}</Typography>
                  <Typography variant="body2" color="text.secondary">
                    {service.description}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default ServicePage;
