import React from 'react';
import { Container, Typography, Button, Grid } from '@mui/material';
import { Link } from 'react-router-dom';

const WinnersMenuPage = () => {
  return (
    <Container>
      <Typography variant="h4" align="center" gutterBottom>
        Select a Contest to View Winners
      </Typography>
      <Grid container spacing={3} justifyContent="center" style={{ marginTop: '20px' }}>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            component={Link}
            to="/winners"
            size="large"
          >
            Ganpati Bappa Contest Winners
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="secondary"
            component={Link}
            to="/navaratri-winners"
            size="large"
          >
            Navaratri 2024 Winners
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

export default WinnersMenuPage;
