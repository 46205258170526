import React from 'react';
import { Box, Container, Typography, Grid, Link } from '@mui/material';
import aboutMeImage from '../Images/aboutme.png'; // Adjust the path accordingly
import servicesImage from '../Images/Services.png'; // Adjust the path accordingly
import contactImage from '../Images/Contact.png'; // Adjust the path accordingly
import businessImage from '../Images/buisness.png'; // Adjust the path accordingly

function Footer() {
  return (
    <footer style={{ backgroundColor: '#f8f9fa', padding: '20px 0' }}>
      <Container maxWidth="lg">
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} sm={4}>
            <Box textAlign="center">
              <Link href="/about" style={{ textDecoration: 'none' }}>
                <img src={aboutMeImage} alt="About Us" style={{ width: '50px', height: '50px', cursor: 'pointer' }} />
              </Link>
              <Typography variant="h6" gutterBottom>
                About Us
              </Typography>
              <Typography variant="body2">
                Learn more about our mission and values.
              </Typography>
              <Link href="/about" color="primary" variant="body2">
                Read More
              </Link>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box textAlign="center">
              <Link href="/services" style={{ textDecoration: 'none' }}>
                <img src={servicesImage} alt="Services" style={{ width: '50px', height: '50px', cursor: 'pointer' }} />
              </Link>
              <Typography variant="h6" gutterBottom>
                Services
              </Typography>
              <Typography variant="body2">
                Explore the services we offer to our clients.
              </Typography>
              <Link href="/services" color="primary" variant="body2">
                View Services
              </Link>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box textAlign="center">
              <Link href="/contact" style={{ textDecoration: 'none' }}>
                <img src={contactImage} alt="Contact" style={{ width: '50px', height: '50px', cursor: 'pointer' }} />
              </Link>
              <Typography variant="h6" gutterBottom>
                Contact
              </Typography>
              <Typography variant="body2">
                Get in touch with us for any inquiries.
              </Typography>
              <Link href="/contact" color="primary" variant="body2">
                Contact Us
              </Link>
            </Box>
          </Grid>
          {/* New Business Registration Section */}
          <Grid item xs={12} sm={4}>
            <Box textAlign="center">
              <Link href="/businessregistration" style={{ textDecoration: 'none' }}>
                <img src={businessImage} alt="Business Registration" style={{ width: '50px', height: '50px', cursor: 'pointer' }} />
              </Link>
              <Typography variant="h6" gutterBottom>
                Business Registration
              </Typography>
              <Typography variant="body2">
                Register your business with us to boost your growth.
              </Typography>
              <Link href="/businessregistration" color="primary" variant="body2">
                Register Now
              </Link>
            </Box>
          </Grid>
        </Grid>

        {/* Copyright and Iconfinder Credit */}
        <Box mt={4} textAlign="center">
          <Typography variant="body2" color="textSecondary">
            <p>
              © {new Date().getFullYear()} Yuvalink. All rights reserved. by Umesh Patil
            </p>
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Icons by <Link href="https://www.iconfinder.com/" target="_blank" rel="noopener" color="primary">Iconfinder</Link>
          </Typography>
        </Box>
      </Container>
    </footer>
  );
}

export default Footer;
