import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import ProductCard from './ProductCard';
import { useNavigate } from 'react-router-dom';
import APIURL from '../config';
import { Helmet } from 'react-helmet';
import {
    Grid,
    TextField,
    CircularProgress,
    Typography,
    Pagination,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from '@mui/material';

function MainContainer() {
    const [products, setProducts] = useState([]);
    const [categories, setCategories] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('');
    const [cart, setCart] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const userId = localStorage.getItem('userId');

    // Fetch products based on selected category and search query
    useEffect(() => {
        const fetchProducts = async () => {
            setLoading(true);
            try {
                // Adjusting the API call to include the category filter
                const response = await axios.get(`${APIURL}/productsListUI`, {
                    headers: { 'Authorization': 'ubpatil' },
                    params: { category: selectedCategory } // Pass the selected category
                });
                console.log('Products fetched:', response.data);
                setProducts(response.data);
            } catch (error) {
                console.error('Error fetching products:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchProducts();
    }, [selectedCategory]); // Re-fetch products when selectedCategory changes

    // Fetch categories from the API
    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await axios.get(`${APIURL}/productcategories`, { // Corrected endpoint
                    headers: { 'Authorization': 'ubpatil' },
                });
                setCategories(response.data);
            } catch (error) {
                console.error('Error fetching categories:', error);
            }
        };
        fetchCategories();
    }, []);

    // Fetch cart items from the API
    useEffect(() => {
        const fetchCart = async () => {
            try {
                const response = await axios.get(`${APIURL}/cart/${userId}`, {
                    headers: { 'Authorization': 'ubpatil' },
                });
                setCart(response.data);
            } catch (error) {
                console.error('Error fetching cart:', error);
            }
        };
        fetchCart();
    }, [userId]);

    // Handle adding product to cart
    const handleAddToCart = useCallback((productId) => {
        if (!userId) {
            navigate('/login');
            return;
        }

        axios.post(`${APIURL}/cart`, {
            userId: userId,
            productId: productId,
            quantity: 1
        }, { headers: { 'Authorization': 'ubpatil' } })
            .then(response => {
                setCart(prevCart => [...prevCart, response.data]);
                navigate('/cart');
            })
            .catch(error => {
                console.error('Error adding to cart:', error);
            });
    }, [navigate, userId]);

    // Filter products based on search query
    const searchedProducts = products.filter(product => 
        product.title.toLowerCase().includes(searchQuery.toLowerCase())
    );

    console.log('Searched Products:', searchedProducts);

    // Paginate products
    const paginatedProducts = searchedProducts.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    // Calculate total pages for pagination
    const totalPages = Math.ceil(searchedProducts.length / itemsPerPage);

    // Handle page change
    const handlePageChange = (event, pageNumber) => {
        setCurrentPage(pageNumber);
    };

    return (
        <div className="container">
            <Helmet>
                <title>Products List</title>
                <meta name="description" content="Browse our collection of products. Shop now for the best quality products." />
            </Helmet>
            {loading && <CircularProgress />}
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="Search List..."
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        style={{ marginBottom: '16px' }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth variant="outlined" style={{ marginBottom: '16px' }}>
                        <InputLabel>Category</InputLabel>
                        <Select
                            value={selectedCategory}
                            onChange={(e) => setSelectedCategory(e.target.value)} 
                            label="Category"
                        >
                            <MenuItem value="">
                                <em>All</em>
                            </MenuItem>
                            {categories.map(category => (
                                <MenuItem key={category.id} value={category.id}>
                                    {category.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        {paginatedProducts.length > 0 ? (
                            paginatedProducts.map(product => (
                                <Grid item xs={6} sm={4} md={3} key={product.id}>
                                    <ProductCard
                                        id={product.id}
                                        likes={product.likes}
                                        image={`${APIURL}/Images/${product.id}.jpg`}
                                        title={product.title}
                                        price={`₹${product.price}`}
                                        onAddToCart={() => handleAddToCart(product.id)}
                                    />
                                </Grid>
                            ))
                        ) : (
                            <Grid item xs={12}>
                                <Typography>No products found.</Typography>
                            </Grid>
                        )}
                    </Grid>
                    {totalPages > 1 && (
                        <Pagination
                            count={totalPages}
                            page={currentPage}
                            onChange={handlePageChange}
                            variant="outlined"
                            color="primary"
                            style={{ marginTop: '16px', display: 'flex', justifyContent: 'center' }}
                        />
                    )}
                </Grid>
            </Grid>
        </div>
    );
}

export default MainContainer;
