import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Select, MenuItem, TextField, Typography, Card, CardContent, CardMedia, Pagination, Modal } from '@mui/material';
import APIURL from '../../config';
import './CSS/AdminPage.css'; // Import CSS file for styling

function AdminPage() {
    const [participants, setParticipants] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [sort, setSort] = useState('name');
    const [filter, setFilter] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [zoomedImage, setZoomedImage] = useState(null);

    useEffect(() => {
        axios.get(`${APIURL}/adminparticipants`, {
            params: {
                page: currentPage,
                limit: 10,
                sort,
                filter
            }
        })
        .then(response => {
            console.log('API response:', response.data);
            if (response.data && Array.isArray(response.data.participants)) {
                setParticipants(response.data.participants);
                setTotalPages(Math.ceil(response.data.totalParticipants / 10));
            } else {
                console.error('Expected an array of participants but got:', response.data);
                setParticipants([]);
            }
        })
        .catch(error => console.error('Error fetching participants:', error));
    }, [currentPage, sort, filter]);

    const handleActivate = (id) => {
        axios.get(`${APIURL}/admin/activate/${id}`)
            .then(response => {
                alert(response.data.message);
                setParticipants(participants.map(p => p.id === id ? { ...p, is_active: true } : p));
            })
            .catch(error => {
                console.error('Error activating participant:', error);
                alert('Failed to activate participant.');
            });
    };

    const handleDeactivate = (id) => {
        axios.get(`${APIURL}/admin/deactivate/${id}`)
            .then(response => {
                alert(response.data.message);
                setParticipants(participants.map(p => p.id === id ? { ...p, is_active: false } : p));
            })
            .catch(error => {
                console.error('Error deactivating participant:', error);
                alert('Failed to deactivate participant.');
            });
    };

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    const handleSortChange = (e) => {
        setSort(e.target.value);
    };

    const handleFilterChange = (e) => {
        setFilter(e.target.value);
    };

    const handleImageClick = (image) => {
        setZoomedImage(image);
    };

    const closeZoomedImage = () => {
        setZoomedImage(null);
    };

    const generateWhatsAppLink = (participant) => {
        const message = `Hi! I need your help to support ${participant.name} in a contest. Please like their profile using this link:\n\nhttps://yuvalink.com/participantsPage?participantId=${participant.id}\n\nGanpati Bappa Moraya! Your support means a lot and could help them win the top spot! Thank you!`;
        return `https://wa.me/?text=${encodeURIComponent(message)}`;
    };

    const handleSendWhatsApp = (participant) => {
        const whatsappLink = generateWhatsAppLink(participant);
        window.open(whatsappLink, '_blank');
    };

    return (
        <div className="admin-page">
            <Typography variant="h4" gutterBottom>Admin Page - Manage Participants</Typography>
            <div className="controls">
                <Select onChange={handleSortChange} value={sort}>
                    <MenuItem value="name">Name</MenuItem>
                    <MenuItem value="like_count">Likes</MenuItem>
                </Select>
                <TextField
                    variant="outlined"
                    placeholder="Search by name..."
                    value={filter}
                    onChange={handleFilterChange}
                />
            </div>
            <div className="participants-list">
                {participants.map(participant => (
                    <Card key={participant.id} className="participant-card" sx={{ marginBottom: 2 }}>
                        <CardContent>
                            <Typography variant="h6">{participant.name}</Typography>
                            <CardMedia 
                                component="img"
                                image={`${APIURL}/upload/${participant.image}`} 
                                alt={participant.name} 
                                className="participant-image"
                                onClick={() => handleImageClick(`${APIURL}/upload/${participant.image}`)} 
                            />
                            <Typography>Likes: {participant.like_count}</Typography>
                            <Typography>Mobile: {participant.mobile}</Typography>
                            <Button
                                variant="contained"
                                color={participant.is_active ? "warning" : "success"}
                                onClick={() => participant.is_active ? handleDeactivate(participant.id) : handleActivate(participant.id)}
                                disabled={participant.is_active === undefined}
                            >
                                {participant.is_active ? 'Deactivate' : 'Activate'}
                            </Button>
                            <Button
                                variant="outlined"
                                onClick={() => handleSendWhatsApp(participant)}
                            >
                                Send WhatsApp
                            </Button>
                        </CardContent>
                    </Card>
                ))}
            </div>
            <Pagination 
                count={totalPages} 
                page={currentPage} 
                onChange={handlePageChange} 
                color="primary"
            />

            <Modal open={Boolean(zoomedImage)} onClose={closeZoomedImage}>
                <div className="modal-content">
                    <img src={zoomedImage} alt="Zoomed" className="zoomed-image" />
                </div>
            </Modal>
        </div>
    );
}

export default AdminPage;
