import React, { useState } from 'react';
import axios from 'axios';
import { TextField, Button, Typography, Box, Alert } from '@mui/material'; // Import necessary Material-UI components
import APIURL from '../config'; // Import the global API URL

function ContactForm() {
  const [name, setName] = useState(''); // Added state for name
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');
  const [message, setMessage] = useState('');
  const [alert, setAlert] = useState({ type: '', text: '' });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setAlert({ type: '', text: '' });

    if (!name || !email || !mobile || !message) {
      setAlert({ type: 'error', text: 'All fields are required!' });
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setAlert({ type: 'error', text: 'Invalid email address!' });
      return;
    }

    const mobileRegex = /^[0-9]{10}$/;
    if (!mobileRegex.test(mobile)) {
      setAlert({ type: 'error', text: 'Invalid mobile number!' });
      return;
    }

    try {
      await axios.post(`${APIURL}/contact`, { name, email, mobile, message }, { headers: { 'Authorization': 'ubpatil' } });
      setAlert({ type: 'success', text: 'Your message has been sent successfully!' });
      setName('');
      setEmail('');
      setMobile('');
      setMessage('');
    } catch (error) {
      console.error('Error sending message:', error);
      setAlert({ type: 'error', text: 'Failed to send message. Please try again later.' });
    }
  };

  return (
    <Box className="contact-form" sx={{ padding: 3, maxWidth: 400, margin: '0 auto' }}> {/* Center and constrain width */}
      <Typography variant="h4" gutterBottom>Contact Us</Typography>
      {alert.text && (
        <Alert severity={alert.type} sx={{ marginBottom: 2 }}>
          {alert.text}
        </Alert>
      )}
      <form onSubmit={handleSubmit}>
        <TextField
          label="Name"
          variant="outlined"
          fullWidth
          margin="normal"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
        <TextField
          label="Email"
          variant="outlined"
          type="email"
          fullWidth
          margin="normal"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <TextField
          label="Mobile"
          variant="outlined"
          type="text"
          fullWidth
          margin="normal"
          value={mobile}
          onChange={(e) => setMobile(e.target.value)}
          required
        />
        <TextField
          label="Message"
          variant="outlined"
          fullWidth
          margin="normal"
          multiline
          rows={4} // Specify the number of rows for the textarea
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          required
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          sx={{ marginTop: 2 }} // Margin for spacing
        >
          Send Message
        </Button>
      </form>
    </Box>
  );
}

export default ContactForm;
