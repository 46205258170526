import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  TextField,
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import axios from 'axios';
import APIURL from '../../config'; // Import your global API URL

const BusinessManagementPage = () => {
  const [businessId, setBusinessId] = useState('');
  const [businessName, setBusinessName] = useState('');
  const [ownerName, setOwnerName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [categoryId, setCategoryId] = useState('');
  const [products, setProducts] = useState('');
  const [location, setLocation] = useState(''); // New location field
  const [locality, setLocality] = useState(''); // New locality field
  const [businesses, setBusinesses] = useState([]);
  const [categories, setCategories] = useState([]); // State for categories
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [editMode, setEditMode] = useState(false);
  const [currentBusinessId, setCurrentBusinessId] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchQuery, setSearchQuery] = useState(''); // New search query state

  // Fetch businesses and categories
  const fetchBusinesses = async () => {
    try {
      const response = await axios.get(`${APIURL}/businesses`, {
        headers: { 'Content-Type': 'application/json', Authorization: 'ubpatil' },
      });
      const sortedBusinesses = response.data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at)); // Sort by created_at descending
      setBusinesses(sortedBusinesses);
    } catch (error) {
      console.error(error);
      setErrorMessage('Failed to load businesses');
    }
  };

  const fetchCategories = async () => {
    try {
      const response = await axios.get(`${APIURL}/categories`, {
        headers: { 'Content-Type': 'application/json', Authorization: 'ubpatil' },
      });
      setCategories(response.data);
    } catch (error) {
      console.error(error);
      setErrorMessage('Failed to load categories');
    }
  };

  useEffect(() => {
    fetchBusinesses();
    fetchCategories();
  }, []);

  const validateEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  const validatePhone = (phone) => /^[0-9]{10}$/.test(phone);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateEmail(email)) return setErrorMessage('Invalid email format.');
    if (!validatePhone(phone)) return setErrorMessage('Phone number must be 10 digits.');

    const businessData = {
      id: businessId,
      business_name: businessName,
      owner_name: ownerName,
      email,
      phone,
      category_id: categoryId,
      products,
      location,
      locality,
      is_active: true,
    };

    try {
      if (editMode) {
        const response = await axios.put(`${APIURL}/businesses/${currentBusinessId}`, businessData, {
          headers: { 'Content-Type': 'application/json', Authorization: 'ubpatil' },
        });
        if (response.data.success) setSuccessMessage('Business updated successfully!');
      } else {
        const response = await axios.post(`${APIURL}/businesses`, businessData, {
          headers: { 'Content-Type': 'application/json', Authorization: 'ubpatil' },
        });
        if (response.data.success) setSuccessMessage('Business added successfully!');
      }
      // Reset form fields
      resetForm();
      fetchBusinesses();
    } catch (error) {
      console.error(error);
      setErrorMessage('An error occurred. Please try again later.');
    }
  };

  const resetForm = () => {
    setBusinessId('');
    setBusinessName('');
    setOwnerName('');
    setEmail('');
    setPhone('');
    setCategoryId('');
    setProducts('');
    setLocation(''); // Reset location
    setLocality(''); // Reset locality
    setEditMode(false);
    setCurrentBusinessId('');
    setErrorMessage('');
  };

  const handleEdit = (business) => {
    setBusinessId(business.id);
    setBusinessName(business.business_name);
    setOwnerName(business.owner_name);
    setEmail(business.email);
    setPhone(business.phone);
    setCategoryId(business.category_id);
    setProducts(business.products);
    setLocation(business.location); // Populate location for editing
    setLocality(business.locality); // Populate locality for editing
    setEditMode(true);
    setCurrentBusinessId(business.id);
  };

  const handleDelete = async (businessId) => {
    if (window.confirm('Are you sure you want to delete this business?')) {
      try {
        const response = await axios.delete(`${APIURL}/businesses/${businessId}`, {
          headers: { 'Content-Type': 'application/json', Authorization: 'ubpatil' },
        });
        if (response.data.success) {
          setSuccessMessage('Business deleted successfully!');
          fetchBusinesses();
        }
      } catch (error) {
        console.error(error);
        setErrorMessage('Failed to delete business.');
      }
    }
  };

  // Separate function to toggle business status
  const toggleBusinessStatus = async (businessId, isActive) => {
    try {
      const response = await axios.patch(`${APIURL}/businesses/${businessId}`, { is_active: !isActive }, {
        headers: { 'Content-Type': 'application/json', Authorization: 'ubpatil' },
      });
      if (response.data.success) {
        return true; // Return true if the operation was successful
      }
    } catch (error) {
      console.error(error);
      setErrorMessage('Failed to toggle business status.');
    }
    return false; // Return false if there was an error
  };

  const handleToggleActive = async (businessId, isActive) => {
    const success = await toggleBusinessStatus(businessId, isActive);
    if (success) {
      setSuccessMessage(`Business ${isActive ? 'deactivated' : 'activated'} successfully!`);
      fetchBusinesses();
    }
  };

  const handleChangePage = (event, newPage) => setPage(newPage);
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Filter businesses based on the search query
  const filteredBusinesses = businesses.filter(business => 
    business.business_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
    business.owner_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
    business.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
    business.phone.includes(searchQuery)
  );

  return (
    <Container>
      <Typography variant="h4" align="center" gutterBottom>
        Business Management
      </Typography>
      {errorMessage && <Typography color="error">{errorMessage}</Typography>}
      {successMessage && <Typography color="primary">{successMessage}</Typography>}

      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField fullWidth label="Business ID" variant="outlined" value={businessId} disabled />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Business Name"
              variant="outlined"
              value={businessName}
              onChange={(e) => setBusinessName(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Owner Name"
              variant="outlined"
              value={ownerName}
              onChange={(e) => setOwnerName(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField fullWidth label="Email" variant="outlined" value={email} onChange={(e) => setEmail(e.target.value)} type="email" />
          </Grid>
          <Grid item xs={12}>
            <TextField fullWidth label="Phone" variant="outlined" value={phone} onChange={(e) => setPhone(e.target.value)} />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>Category</InputLabel>
              <Select
                value={categoryId}
                onChange={(e) => setCategoryId(e.target.value)}
                label="Category"
              >
                {categories.map(category => (
                  <MenuItem key={category.id} value={category.id}>{category.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Products"
              variant="outlined"
              value={products}
              onChange={(e) => setProducts(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Location"
              variant="outlined"
              value={location}
              onChange={(e) => setLocation(e.target.value)} // Location field
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Locality"
              variant="outlined"
              value={locality}
              onChange={(e) => setLocality(e.target.value)} // Locality field
            />
          </Grid>
          <Grid item xs={12}>
            <Button type="submit" variant="contained" color="primary">
              {editMode ? 'Update Business' : 'Add Business'}
            </Button>
            <Button onClick={resetForm} variant="outlined" color="secondary" style={{ marginLeft: '10px' }}>
              Cancel
            </Button>
          </Grid>
        </Grid>
      </form>

      <TextField
        fullWidth
        label="Search"
        variant="outlined"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        style={{ marginTop: '20px' }}
      />

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Business Name</TableCell>
              <TableCell>Owner Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Phone</TableCell>
              <TableCell>Category</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredBusinesses.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((business) => (
              <TableRow key={business.id}>
                <TableCell>{business.business_name}</TableCell>
                <TableCell>{business.owner_name}</TableCell>
                <TableCell>{business.email}</TableCell>
                <TableCell>{business.phone}</TableCell>
                <TableCell>{business.category_id}</TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    color={business.is_active ? 'secondary' : 'primary'}
                    onClick={() => handleToggleActive(business.id, business.is_active)}
                  >
                    {business.is_active ? 'Deactivate' : 'Activate'}
                  </Button>
                </TableCell>
                <TableCell>
                  <Button variant="contained" color="primary" onClick={() => handleEdit(business)}>
                    Edit
                  </Button>
                  <Button variant="contained" color="error" onClick={() => handleDelete(business.id)} style={{ marginLeft: '10px' }}>
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={filteredBusinesses.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Container>
  );
};

export default BusinessManagementPage;
