import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
  Container,
  Grid,
  Alert,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Pagination,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from '@mui/material';
import API_URL from '../config.js';
import imageCompression from 'browser-image-compression'; // Import the image compression library

const ProductManagement = () => {
  // State for product form
  const [title, setTitle] = useState('');
  const [idolName, setIdolName] = useState('');
  const [price, setPrice] = useState('');
  const [size, setSize] = useState('');
  const [material, setMaterial] = useState('');
  const [features, setFeatures] = useState('');
  const [isActive, setIsActive] = useState(false);
  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(''); // State for image preview
  const [categoryId, setCategoryId] = useState('');
  const [categories, setCategories] = useState([]);
  const [alert, setAlert] = useState(null);

  // State for product listing
  const [products, setProducts] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [search, setSearch] = useState('');
  const [sortOrder, setSortOrder] = useState('title'); // Sort by title or price

  // Fetch categories on component mount
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(`${API_URL}/productcategories`);
        setCategories(response.data);
      } catch (error) {
        console.error('Failed to fetch categories:', error);
      }
    };
    fetchCategories();
  }, []);

  // Fetch products on page change or when search/sortOrder changes
  useEffect(() => {
    fetchProducts(page, search, sortOrder);
  }, [page, search, sortOrder]);

  // Fetch products with pagination, search, and sorting
  const fetchProducts = async (page, search, sortOrder) => {
    const userId = localStorage.getItem('userId'); // Retrieve userId here
  
    if (!userId) {
      console.error('User ID is missing');
      return;
    }
  
    try {
      const response = await axios.get(`${API_URL}/productsList`, {
        params: { page, search, sortOrder, userId },
      });
  
      if (response.data && response.data.products) {
        setProducts(response.data.products);
        setTotalPages(response.data.totalPages);
      } else {
        setProducts([]);
      }
    } catch (error) {
      console.error('Failed to fetch products:', error);
      setProducts([]);
    }
  };
  
  // Add product handler
  const handleAddProduct = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('title', title);
    formData.append('idolName', idolName);
    formData.append('price', price);
    formData.append('size', size);
    formData.append('material', material);
    formData.append('features', features);
    formData.append('isActive', 0);
    formData.append('categoryId', categoryId); // Category ID from dropdown
    formData.append('userId', localStorage.getItem('userId')); // User ID from localStorage

    if (image) {
      formData.append('image', image);
    }

    try {
      await axios.post(`${API_URL}/productInserts_New`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': 'ubpatil',
        },
      });
      setAlert({ type: 'success', text: 'Product added successfully!' });
      // Reset form fields
      resetForm();
      fetchProducts(page, search, sortOrder); // Refresh product list
    } catch (error) {
      setAlert({ type: 'error', text: 'Failed to add product. Please try again later.' });
    }
  };

  // Function to reset the form fields
  const resetForm = () => {
    setTitle('');
    setIdolName('');
    setPrice('');
    setSize('');
    setMaterial('');
    setFeatures('');
    setIsActive(true);
    setCategoryId('');
    setImage(null);
    setImagePreview('');
  };

  // Handle image selection and compression
  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      try {
        // Compress the image
        const options = {
          maxSizeMB: 1, // Maximum size in MB
          maxWidthOrHeight: 400, // Resize to max 300 pixels
          useWebWorker: true,
        };
        const compressedFile = await imageCompression(file, options);
        setImage(compressedFile);
        
        // Create image preview
        const previewUrl = URL.createObjectURL(compressedFile);
        setImagePreview(previewUrl);
      } catch (error) {
        console.error('Image compression failed:', error);
      }
    }
  };

  // Handle pagination
  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleToggleActive = async (productId, isActive) => {
    try {
      await axios.post(`${API_URL}/toggleProductActiveList`, { productId, isActive });
      fetchProducts(page, search); // Refresh product list after updating status
    } catch (error) {
      console.error('Failed to update product status:', error);
    }
  };

  return (
    <Container maxWidth="md" style={{ marginTop: '20px' }}>
      {/* Add Product Form */}
      <Typography variant="h4" gutterBottom>Add Product</Typography>
      {alert && <Alert severity={alert.type === 'success' ? 'success' : 'error'}>{alert.text}</Alert>}
      <form onSubmit={handleAddProduct} encType="multipart/form-data">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="Title"
              fullWidth
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
              inputProps={{ style: { fontSize: '1rem' } }} // Adjust font size
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Other Name"
              fullWidth
              value={idolName}
              onChange={(e) => setIdolName(e.target.value)}
              required
              inputProps={{ style: { fontSize: '1rem' } }} // Adjust font size
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Price"
              type="number"
              fullWidth
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              required
              inputProps={{ style: { fontSize: '1rem' } }} // Adjust font size
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Size"
              fullWidth
              value={size}
              onChange={(e) => setSize(e.target.value)}
              required
              inputProps={{ style: { fontSize: '1rem' } }} // Adjust font size
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Short Details"
              fullWidth
              value={material}
              onChange={(e) => setMaterial(e.target.value)}
              inputProps={{ style: { fontSize: '1rem' } }} // Adjust font size
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Features"
              fullWidth
              value={features}
              onChange={(e) => setFeatures(e.target.value)}
              inputProps={{ style: { fontSize: '1rem' } }} // Adjust font size
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox checked={isActive} disabled />
              }
              label="Is Active"
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel>Select Category</InputLabel>
              <Select
                value={categoryId}
                onChange={(e) => setCategoryId(e.target.value)}
                required
                inputProps={{ style: { fontSize: '1rem' } }} // Adjust font size
              >
                {categories.map((category) => (
                  <MenuItem key={category.id} value={category.id}>
                    {category.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <input
              type="file"
              accept="image/*"
              onChange={handleImageChange}
              required
              style={{ fontSize: '1rem' }}
            />
            {imagePreview && <img src={imagePreview} alt="Preview" style={{ maxWidth: '100%', marginTop: '10px' }} />}
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" type="submit" fullWidth>
              Add Product
            </Button>
          </Grid>
        </Grid>
      </form>

      {/* Product List */}
      <Typography variant="h4" gutterBottom style={{ marginTop: '40px' }}>
        Product List
      </Typography>
      <TextField
        label="Search"
        fullWidth
        value={search}
        onChange={(e) => {
          setSearch(e.target.value);
          setPage(1); // Reset to first page on search
        }}
        style={{ marginBottom: '20px' }}
      />
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <InputLabel>Sort By</InputLabel>
            <Select
              value={sortOrder}
              onChange={(e) => {
                setSortOrder(e.target.value);
                setPage(1); // Reset to first page on sort change
              }}
            >
              <MenuItem value="title">Title</MenuItem>
              <MenuItem value="price">Price</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Title</TableCell>
              <TableCell>Other Name</TableCell>
              <TableCell>Price</TableCell>
              <TableCell>Size</TableCell>
              <TableCell>Material</TableCell>
              <TableCell>Features</TableCell>
              <TableCell>Is Active</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {products.map((product) => (
              <TableRow key={product.id}>
                <TableCell>{product.title}</TableCell>
                <TableCell>{product.idolName}</TableCell>
                <TableCell>{product.price}</TableCell>
                <TableCell>{product.size}</TableCell>
                <TableCell>{product.material}</TableCell>
                <TableCell>{product.features}</TableCell>
                <TableCell>
                  <Button
                      variant="contained"
                      color={product.isActive ? 'secondary' : 'primary'}
                      onClick={() => handleToggleActive(product.id, !product.isActive)}
                    >
                      {product.isActive ? 'Deactivate' : 'Activate'}
                    </Button>
                </TableCell>
                
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        count={totalPages}
        page={page}
        onChange={handlePageChange}
        style={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}
      />
    </Container>
  );
};

export default ProductManagement;
