import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar'; 
import Footer from './components/Footer';
import MainContainer from './components/MainContainer';
import CartPage from './components/CartPage';
import UploadP from './components/UploadP';
import ContactForm from './components/ContactForm';
import AboutUs from './components/AboutUs'; 
import LoginPage from './components/LoginPage';
import SignupPage from './components/SignupPage';
import ServicePage from './components/ServicePage';
//Admin panel
import AdminDashboard from './components/Admin/AdminDashboard';  
import AddProduct from './components/Admin/AddProduct';
import ProductList from './components/Admin/ProductList';
import UserList from './components/Admin/UserList';
import ContactPanel from './components/Admin/ContactPanel';
import OrderDetails from './components/Admin/OrderDetails';
import ViewOrders from './components/Admin/ViewOrders';
import ReportPage from './components/Admin/ReportPage'; 
import Breadcrumbs from './components/Breadcrumbs'; // Example component
import ResetPassword from './components/ResetPassword'; // Make sure the path is correct
import EmailVerification from './components/EmailVerification'; // Make sure the path is correct
import AuthWrapper from './components/Admin/AuthWrapper'; // Import AuthWrapper
import AdminPage from './components/Admin/AdminPage'; // Import AdminPage
import LeadDetails from './components/Admin/LeadDetails'; // Import AdminPage
import UploadProduct from './components/Admin/UploadProduct';
import CategoryManagementPage from './components/Admin/CategoryManagementPage';
import BusinessManagementPage from './components/Admin/BusinessManagementPage';
import Logout from './components/Logout';
import UploadPage from './components/UploadPage';
import ParticipantsPage from './components/ParticipantsPage';
import WinnerPage from './components/WinnerPage';
import KhandeshSignature from './components/KhandeshSignature';
//import PartnerPage from './components/PartnerPage';
import PartnerPage from './components/PartnerPage';
import CondolencePage from './components/CondolencePage';
import GlobalTravelServices from './components/GlobalTravelServices';
import NavaratriWinnerPage from './components/NavaratriWinnerPage';
import TopContestantsPage from './components/TopContestantsPage'; // Correct import path 
import LetterPage from './components/LetterPage'; // Correct import path 
import Participants from './components/Participants'; // Correct import path 
import ContestPage from './components/ContestPage'; // Correct import path 
import BusinessDirectory from './components/BusinessDirectory'; // Correct import path 
import HappyDasaraBanner from './components/HappyDasaraBanner'; // Correct import path 
import BusinessRegistrationPage from './components/BusinessRegistrationPage'; // Correct import path 
import CertificateGenerator from './components/CertificateGenerator'; // Correct import path
import SocialInitiativesPage from './components/SocialInitiativesPage'; // Correct import path  
import WinnersMenuPage from './components/WinnersMenuPage';
import ProductManagement from './components/ProductManagement';
import ProductCategoryManagementPage from './components/Admin/ProductCategoryManagementPage';
import 'bootstrap/dist/css/bootstrap.min.css'; 
import './App.css'; 
import MobileAppPage from './components/MobileAppPage';
import AdminMenu from './components/AdminMenu';
import { ContactPage } from '@mui/icons-material';

function App() {
  return (
    <Router>
      <div>
        <Navbar />
        <Breadcrumbs />
        <Routes>
          <Route path="/" element={<MainContainer />} />
          <Route path="/cart" element={<CartPage />} /> 
          <Route path="/partners" element={<PartnerPage />} />
          <Route path="/globaltravelservices" element={<GlobalTravelServices />} />
          <Route path="/contact" element={<ContactForm />} />
          <Route path="/contest" element={<ContestPage />} /> 
          <Route path="/winners" element={<WinnerPage />} />
          <Route path="/businessregistration" element={<BusinessRegistrationPage />} />
          <Route path="/navaratri-winners" element={<NavaratriWinnerPage />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/products" element={<MainContainer />} />
          <Route path="/businessdirectory" element={<BusinessDirectory />} />
          <Route path="/ratantata" element={<CondolencePage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/signup" element={<SignupPage />} />  
          <Route path="/addProducts" element={<ProductManagement />} /> 
          <Route path="/adminmenu" element={<AdminMenu />} />   
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/verify-email" element={<EmailVerification />} /> 
          <Route path="/socialinitiatives" element={<SocialInitiativesPage />} /> 
          <Route path="/winnersMenu" element={<WinnersMenuPage />} /> 
          <Route path="/banner" element={<HappyDasaraBanner />} /> 
          <Route path="/uploadPage" element={<UploadP />} />
          <Route path="/upload" element={<UploadPage />} />
          <Route path="/services" element={<ServicePage />} />
          <Route path="/admin/view-my-orders" element={<ViewOrders />} />
          <Route path="/participants" element={<ParticipantsPage />} />
          <Route path="/ParticipantsPage" element={<Participants />} />
          <Route path="/esign" element={<LetterPage />} />
          <Route path="/badlapurkar_esign" element={<KhandeshSignature />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/certificate" element={<CertificateGenerator />} /> 
          <Route path="/topContestantsPage" element={<TopContestantsPage />} />
          <Route path="/topContestants" element={<TopContestantsPage />} />
          <Route path="/mobile-app" element={<MobileAppPage />} />
          <Route path="/admin/dashboard" element={<AuthWrapper><AdminDashboard /></AuthWrapper>} />
                <Route path="/admin/dashboard/add-product" element={<AuthWrapper><AddProduct /></AuthWrapper>} />
                <Route path="/admin/dashboard/leads" element={<AuthWrapper><LeadDetails /></AuthWrapper>} />
                <Route path="/admin/dashboard/upload-product" element={<AuthWrapper><UploadProduct /></AuthWrapper>} />
                <Route path="/admin/dashboard/view-orders" element={<AuthWrapper><OrderDetails /></AuthWrapper>} />
                <Route path="/admin/dashboard/reports" element={<AuthWrapper><ReportPage /></AuthWrapper>} />
                <Route path="/admin/dashboard/product-list" element={<AuthWrapper><ProductList /></AuthWrapper>} />
                <Route path="/admin/dashboard/user-list" element={<AuthWrapper><UserList /></AuthWrapper>} />
                <Route path="/admin/dashboard/contact-list" element={<AuthWrapper><ContactPanel /></AuthWrapper>} />
                <Route path="/admin/dashboard/adminpage" element={<AuthWrapper><AdminPage /></AuthWrapper>} /> 
                <Route path="/admin/dashboard/category" element={<AuthWrapper><CategoryManagementPage /></AuthWrapper>} /> 
                <Route path="/admin/dashboard/bms" element={<AuthWrapper><BusinessManagementPage /></AuthWrapper>} /> 
                <Route path="/admin/dashboard/pcm" element={<AuthWrapper><ProductCategoryManagementPage /></AuthWrapper>} /> 
                <Route path="/invitation" element={<ContactPanel />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
