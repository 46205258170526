import React, { useRef } from 'react';
import { Button, TextField, Container, Typography, Box, Link } from '@mui/material';
import { toJpeg } from 'html-to-image';
import { saveAs } from 'file-saver';

const CertificateGenerator = () => {
  const [name, setName] = React.useState('');
  const [address, setAddress] = React.useState('');
  const certificateRef = useRef(null);

  const handleDownload = () => {
    if (certificateRef.current === null) {
      return;
    }

    toJpeg(certificateRef.current, { quality: 0.95 })
      .then((dataUrl) => {
        saveAs(dataUrl, 'participation-certificate.jpg');
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Ganpati Bappa Contest 2024: Upload Your Entry
      </Typography>

      <Box component="form" noValidate autoComplete="off" mb={4}>
        <TextField
          label="Name"
          variant="outlined"
          fullWidth
          margin="normal"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <TextField
          label="Address"
          variant="outlined"
          fullWidth
          margin="normal"
          value={address}
          onChange={(e) => setAddress(e.target.value)}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleDownload}
          sx={{ mt: 2 }}
        >
          Download Certificate
        </Button>
      </Box>

      <Box
        ref={certificateRef}
        sx={{
          width: '100%',
          height: '450px',
          border: '2px solid black',
          padding: '20px',
          textAlign: 'center',
          backgroundColor: '#f5f5f5',
          position: 'relative',
        }}
      >
        {/* Add Logo at the Top */}
        <Box
          component="img"
          src="logo.png" // Replace with the actual path to the logo image
          alt="Logo"
          sx={{ width: '80px', height: '80px', margin: '0 auto' }}
        />

        {/* Header for Ganpati Bappa Morya */}
        <Typography variant="h4" color="orange" fontWeight="bold" mb={2}>
          Ganpati Bappa Morya
        </Typography>

        <Typography variant="h3">Participation Certificate</Typography>
        <Typography variant="h5" mt={2}>
          Congratulations, {name}!
        </Typography>
        <Typography variant="h6" mt={1}>
          Address: {address}
        </Typography>
        <Typography variant="h6" mt={4}>
          Thank you for participating in the Ganpati Bappa Contest 2024!
        </Typography>

        {/* Footer Section */}
        <Typography variant="body1" mt={2}>
          Contest by <strong>YuvaLink.com</strong>
        </Typography>
        <Typography
          variant="body2"
          color="textSecondary"
          sx={{ position: 'absolute', bottom: 10, width: '100%' }}
        >
          Visit us at{' '}
          <Link href="https://yuvalink.com/" target="_blank" rel="noopener noreferrer">
            www.yuvalink.com
          </Link>{' '}
          by Umesh Patil
        </Typography>
      </Box>
    </Container>
  );
};

export default CertificateGenerator;
