import React, { useState, useEffect } from 'react';
import axios from 'axios';
import API_URL from '../config'; 

import {
  Container,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  Paper,
  Box,
  Alert,
  TextField,
} from '@mui/material';
import WhatsAppImage from '../Images/whatsup.png'; // Import the image here 

const Loader = () => (
  <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
    <CircularProgress />
  </Box>
);

function KhandeshSignature() {
  const [signature, setSignature] = useState('');
  const [userName, setUserName] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [city, setCity] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [signatures, setSignatures] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('');

  useEffect(() => {
    fetchSignatures(page);
  }, [page]);

  const fetchSignatures = async (page) => {
    try {
      const response = await axios.get(`${API_URL}/khandeshsignatures?page=${page}&limit=20`);
      setSignatures(response.data.signatures || []);
      setTotalPages(response.data.totalPages || 1);
    } catch (error) {
      console.error('स्वाक्षर्या प्राप्त करण्यात त्रुटी', error);
    }
  };

  const handleSign = async () => {
    if (!userName || !userEmail || !signature || !city || !mobileNumber) {
      alert('सर्व क्षेत्रे आवश्यक आहेत.');
      return;
    }

    // Validate email format
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(userEmail)) {
      alert('कृपया वैध ई-मेल पत्ता प्रविष्ट करा.');
      return;
    }

    // Validate mobile number format
    const mobilePattern = /^[0-9]{10}$/;
    if (!mobilePattern.test(mobileNumber)) {
      alert('कृपया वैध 10-आंक्य मोबाइल नंबर प्रविष्ट करा.');
      return;
    }

    // Confirm submission
    const confirmation = window.confirm(
      `कृपया तुमच्या तपशीलांची पुष्टी करा:\n\n` +
      `नाव: ${userName}\n` +
      `ई-मेल: ${userEmail}\n` +
      `स्वाक्षरी: ${signature}\n` +
      `शहर: ${city}\n` +
      `मोबाइल नंबर: ${mobileNumber}\n\n` +
      `तुम्हाला ही माहिती सादर करायची आहे का? कृपया सत्यापनासाठी तुमच्या ई-मेलची तपासणी करा`
    );

    if (confirmation) {
      setIsLoading(true);

      try {
        const response = await axios.post(`${API_URL}/khandeshsignatures`, {
          user_name: userName,
          user_email: userEmail,
          signature_text: signature,
          city: city,
          mobile_number: mobileNumber,
        }, {
          headers: {
            'Authorization': 'ubpatil',
          },
        });

        if (response.data.success) {
          setMessage('कागदपत्र यशस्वीरित्या स्वाक्षरी केले! .');
          setSignature('');
          setUserName('');
          setUserEmail('');
          setCity('');
          setMobileNumber('');
          fetchSignatures(page);
        } else {
          alert(response.data.error || 'कागदपत्र स्वाक्षरी करण्यात अयशस्वी.');
        }
      } catch (error) {
        console.error("कागदपत्रावर स्वाक्षरी करण्यामध्ये त्रुटी:", error);
        alert('एक त्रुटी घडली. कृपया नंतर पुन्हा प्रयत्न करा.');
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handlePageChange = (newPage) => {
    if (newPage < 1 || newPage > totalPages) return;
    setPage(newPage);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }; 
  
  // Function to mask mobile number
  
  const shareOnWhatsApp = () => {
    const currentPageURL = window.location.href; // Get the current page URL
    const message = `सदर विनंती पत्र:\n\nप्रति,\nमहाव्यवस्थापक,\nमुंबई लोकल रेल्वे विभाग,\nमुंबई\n\nविनंती: बदलापूर-मुंबई दरम्यान लोकल ट्रेनचं वेळापत्रक नियमित ठेवणे आणि फ्रिक्वेन्सी वाढवणे.\n\nअधिक माहितीसाठी भेट द्या आणि आपले मत मांडण्यासाठी स्वाक्षरी करा :\n${currentPageURL}`;
    const whatsappURL = `https://wa.me/?text=${encodeURIComponent(message)}`;
    window.open(whatsappURL, '_blank');
  };
  
  

  return (
    <Container maxWidth="md">
      {isLoading && <Loader />}
 

      {/* New Section for Request Message */}
      <Paper elevation={2} style={{ padding: '16px', marginBottom: '16px' }}>
      
        <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
      
प्रति,<br/>
महाव्यवस्थापक,<br/>
मुंबई लोकल रेल्वे विभाग,<br/>
मुंबई

</Typography>
<Typography variant="body1" sx={{ fontWeight: 'bold' }}>
    विषय: बदलापूर-मुंबई दरम्यान लोकल ट्रेनचं वेळापत्रक नियमित वेळेवर ठेवणे आणि फ्रिक्वेन्सी वाढवण्याबाबत विनंती. 
</Typography>
<Typography variant="body1" sx={{ fontWeight: 'bold' }}>दिनांक: ०८/१०/२०२४</Typography> 
        <Typography variant="body1" style={{ marginTop: '16px' }}>
        महोदय, 
        </Typography>
        <Typography variant="body1" style={{ marginTop: '16px' }}>
        बदलापूर-मुंबई दरम्यान धावणारी लोकल ट्रेन आमच्यासाठी अत्यंत महत्त्वाची आहे. लोकल ट्रेनमुळे हजारो प्रवासी दररोज आपल्या कामावर वेळेत पोहोचतात आणि त्यांच्या दैनंदिन जीवनाची वाहिनी सुरू ठेवतात. यासाठी आम्ही आपल्या टीमचे आणि स्टेशनमास्तरांचे आभारी आहोत. आमच्यासाठी ते खरोखरच देवासारखे आहेत. मात्र, या ट्रेनच्या फ्रिक्वेन्सीमध्ये आणि वेळापत्रकात काही सुधारणा होण्याची आवश्यकता आहे.
        </Typography>
        <Typography variant="body1" style={{ marginTop: '16px' }}>
        आम्ही बदलापूर येथील रहिवासी आपल्या लक्षात आणून देऊ इच्छितो की, बदलापूर-मुंबई दरम्यान धावणाऱ्या लोकल ट्रेनची संख्या अपुरी असल्यामुळे अनेक प्रवाशांना मोठ्या समस्यांना सामोरे जावे लागत आहे. विशेषतः शालेय विद्यार्थी, नोकरदार, वृद्ध नागरिक, आणि गरोदर महिलांच्या दृष्टीने ही परिस्थिती अत्यंत चिंताजनक आहे.

</Typography>
        <Typography variant="body1" style={{ marginTop: '16px' }}>
        गर्दीमुळे अनेक प्रवाशांना लेटमार्क मिळतात, काही आजारी पडतात, आणि गरोदर महिलांना प्रचंड त्रास सहन करावा लागतो. रेल्वेची कमी फ्रिक्वेन्सी यास मुख्य कारण आहे. यासाठी आम्ही आपणाकडे विनंती करतो की, बदलापूर-मुंबई दरम्यान धावणाऱ्या लोकल ट्रेनची फ्रिक्वेन्सी वाढविण्यासाठी त्वरित उपाययोजना करण्यात यावी.

        </Typography>
        <Typography variant="body1" style={{ marginTop: '16px' }}>
        खास मुद्दा:
खूप महत्त्वाचं म्हणजे, काहीं लोकल ट्रेनचं वेळापत्रक नेहमीच विस्कटलेलं असतं. यामुळे अनेक प्रवाशांना वेळेत पोहोचणे शक्य होत नाही आणि त्यांच्या कामांवर व दैनंदिन जीवनावर याचा नकारात्मक परिणाम होतो. लोकल ट्रेन नेहमी नियमितपणे आणि वेळेवर धावली पाहिजे, यासाठी योग्य नियोजन करण्यात यावे अशी आमची विनंती आहे.

        </Typography>
        <Typography variant="body1" style={{ marginTop: '16px' }}>
        या विषयावर आपण सहकार्य कराल अशी आम्ही आशा करतो. या याचिकेसाठी आम्ही ऑनलाईन सही मोहीम सुरु केली असून त्यास प्रवाशांकडून चांगला प्रतिसाद मिळाला आहे. यासोबत त्या सही मोहीमेत सहभाग घेतलेल्या प्रवाशांची सही यादी जोडली आहे.

        </Typography>
        <Typography variant="body1" style={{ marginTop: '16px' }}>
        आपल्या सहकार्याची अपेक्षा.

धन्यवाद!

आपला विश्वासू,
बदलापूर प्रवासी

        </Typography>
        <Typography variant="body1" style={{ marginTop: '16px' }}>
        हे पत्र तुमच्या तर्फे ऑनलाईन सहीसह रेल्वे अधिकाऱ्यांकडे सादर केले जाईल
        </Typography>
      </Paper>

      <Paper elevation={2} style={{ padding: '16px', marginBottom: '16px' }}>
      <Typography variant="h6">स्वाक्षरी देण्यासाठी माहिती भरा:</Typography>
<Typography variant="body2" color="error">
  कृपया चुकीचा डेटा टाकू नका.स्वाक्षरी ठिकाणी तुमचे नाव टाका. सामाजिक जाणीव ठेवून हे कार्य आपण करत आहोत.

</Typography>

        <TextField
          label="तुमचे नाव"
          fullWidth
          margin="normal"
          value={userName}
          onChange={(e) => setUserName(e.target.value)}
        />
        <TextField
          label="तुमचा ई-मेल"
          fullWidth
          margin="normal"
          value={userEmail}
          onChange={(e) => setUserEmail(e.target.value)}
        />
        <TextField
          label="स्वाक्षरी नाव"
          fullWidth
          margin="normal"
          value={signature}
          onChange={(e) => setSignature(e.target.value)}
        />
        <TextField
          label="शहर"
          fullWidth
          margin="normal"
          value={city}
          onChange={(e) => setCity(e.target.value)}
        />
        <TextField
          label="मोबाइल नंबर"
          fullWidth
          margin="normal"
          value={mobileNumber}
          onChange={(e) => setMobileNumber(e.target.value)}
        />
        <Button variant="contained" color="primary" onClick={handleSign}>
          स्वाक्षरी करा
        </Button>
        {message && <Alert severity="success" style={{ marginTop: '16px' }}>{message}</Alert>}

        <Box display="flex" justifyContent="flex-start" mt={2}>
          <Button
            variant="contained"
            startIcon={<img src={WhatsAppImage} alt="WhatsApp" style={{ width: 24, height: 24 }} />}
            onClick={shareOnWhatsApp}
          >
            शेअर करा
          </Button>
        </Box>
      </Paper>

      <Typography variant="h6" gutterBottom>
        स्वाक्षरींची यादी
      </Typography>

      <TableContainer component={Paper}>
  <Table>
    <TableHead>
      <TableRow> 
        <TableCell>तारीख</TableCell>
        <TableCell>नाव</TableCell>
        <TableCell>स्वाक्षरी</TableCell>
        <TableCell>शहर</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {signatures
        .slice()
        .reverse() // Reverse the order to display in descending
        .map((sig, index) => (
          <TableRow key={sig.id}> 
            <TableCell>{formatDate(sig.created_at)}</TableCell>
            <TableCell>{sig.user_name}</TableCell>
            <TableCell
              style={{
                fontFamily: "'Pacifico', 'Cursive', cursive", // Handwriting font
                fontSize: '1.5rem',
                color: '#2c3e50',
                letterSpacing: '1px',
                textAlign: 'center',
              }}
            >
              {sig.signature_text}
            </TableCell>
            <TableCell>{sig.city}</TableCell>
          </TableRow>
        ))}
    </TableBody>
  </Table>
</TableContainer>

<Box display="flex" justifyContent="space-between" marginTop="16px">
  <Button
    onClick={() => handlePageChange(page - 1)}
    disabled={page === 1}
  >
    मागे
  </Button>
  
  <Typography variant="body1">
    Page {page} of {totalPages} {/* Display current page and total pages */}
  </Typography>
  
  <Button
    onClick={() => handlePageChange(page + 1)}
    disabled={page === totalPages}
  >
    पुढे
  </Button>
</Box>

    </Container>
  );
}

export default KhandeshSignature;
