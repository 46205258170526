import React, { useState, useEffect } from 'react';
import { Container, Typography, TextField, Button, Select, MenuItem, FormControl, InputLabel, Grid } from '@mui/material';
import axios from 'axios';
import APIURL from '../config'; // Import the global API URL

const BusinessRegistrationPage = () => {
  const [businessName, setBusinessName] = useState('');
  const [ownerName, setOwnerName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [location, setLocation] = useState(''); // New state for location
  const [locality, setLocality] = useState(''); // New state for locality
  const [categoryId, setCategoryId] = useState('');
  const [otherCategory, setOtherCategory] = useState(''); // State for custom category
  const [products, setProducts] = useState('');
  const [categories, setCategories] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');

  // Fetch categories from the backend
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch(`${APIURL}/categories`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'ubpatil', // Add your authorization token here
          },
        });
        if (!response.ok) throw new Error('Failed to fetch categories');
        const data = await response.json();
        setCategories(data); // Set categories state with fetched data
      } catch (error) {
        console.error(error);
        setErrorMessage('Failed to load categories');
      }
    };
    
    fetchCategories();
  }, []);

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Validate the input
    if (!businessName || !ownerName || !email || !phone || (!categoryId && !otherCategory) || !location || !locality) {
      setErrorMessage('Please fill in all fields.');
      return;
    }

    // Prepare the business data to be sent to the backend
    const businessData = {
      businessName,
      ownerName,
      email,
      phone,
      location, // New field
      locality, // New field
      categoryId: categoryId === 'other' ? 999 : categoryId, // Set categoryId to 999 when "Other" is selected
      otherCategory: categoryId === 'other' ? otherCategory : null, // Save otherCategory if "Other" is selected
      products: products.split(','), // Split products by comma
    };

    try {
      // Send a POST request to the backend to register the business
      const response = await axios.post(`${APIURL}/register-business`, businessData, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'ubpatil',
        },
      });

      // Handle success or error messages
      if (response.data.success) {
        alert('Business registration submitted successfully!');
        // Clear form fields after successful submission
        setBusinessName('');
        setOwnerName('');
        setEmail('');
        setPhone('');
        setLocation(''); // Clear new fields
        setLocality('');
        setCategoryId('');
        setOtherCategory('');
        setProducts('');
        setErrorMessage('');
      } else {
        setErrorMessage(response.data.message || 'Registration failed. Please try again.');
      }
    } catch (error) {
      console.error(error);
      setErrorMessage('An error occurred. Please try again later.');
    }
  };

  return (
    <Container>
      <Typography variant="h4" align="center" gutterBottom>
        Business Registration
      </Typography>
      {errorMessage && <Typography color="error">{errorMessage}</Typography>}
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Business Name"
              variant="outlined"
              value={businessName}
              onChange={(e) => setBusinessName(e.target.value)}
              inputProps={{ maxLength: 50 }} // Limit to 50 characters
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Owner Name"
              variant="outlined"
              value={ownerName}
              onChange={(e) => setOwnerName(e.target.value)}
              inputProps={{ maxLength: 50 }} // Limit to 50 characters
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Email"
              variant="outlined"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              inputProps={{ maxLength: 50 }} // Limit to 100 characters
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Phone"
              variant="outlined"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              inputProps={{ maxLength: 15 }} // Limit to 15 characters
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Location" // New field for location
              variant="outlined"
              value={location}
              onChange={(e) => setLocation(e.target.value)}
              inputProps={{ maxLength: 50 }} // Limit to 50 characters
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Locality" // New field for locality
              variant="outlined"
              value={locality}
              onChange={(e) => setLocality(e.target.value)}
              inputProps={{ maxLength: 50 }} // Limit to 50 characters
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel>Business Category</InputLabel>
              <Select
                value={categoryId}
                onChange={(e) => setCategoryId(e.target.value)}
                label="Business Category"
              >
                {categories.map((cat) => (
                  <MenuItem key={cat.id} value={cat.id}>
                    {cat.name}
                  </MenuItem>
                ))} 
              </Select>
            </FormControl>
          </Grid>
          {categoryId === 'other' && (
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Other Category"
                variant="outlined"
                value={otherCategory}
                onChange={(e) => setOtherCategory(e.target.value)}
                inputProps={{ maxLength: 50 }} // Limit to 50 characters
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Products (comma-separated)"
              variant="outlined"
              value={products}
              onChange={(e) => setProducts(e.target.value)}
              inputProps={{ maxLength: 100 }} // Limit to 200 characters
            />
          </Grid>
          <Grid item xs={12}>
            <Button type="submit" variant="contained" color="primary">
              Register Business
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};

export default BusinessRegistrationPage;
