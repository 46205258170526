import React from 'react';
import { Container, Grid, Card, CardMedia, CardContent, Typography, CardActionArea } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import trainImage from '../Images/Train1.png'; // Adjust path to the train image
import localTrainImage from '../Images/local.png'; // Adjust path to the local train image

const socialInitiatives = [
  {
    id: 1,
    title: 'भुसावळ ते कल्याण आणि पुणे दरम्यान नवीन दररोज ट्रेन सेवेची मागणी',
    description: 'यात नवीन ट्रेन सेवेची मागणी केली जात आहे ज्यामुळे प्रवाशांचे जीवन सोपे होईल.',
    image: trainImage,
    url: '/esign', // URL to redirect when clicked
  },
  {
    id: 2,
    title: 'बदलापूर-मुंबई दरम्यान लोकल ट्रेनचं वेळापत्रक नियमित ठेवणे आणि फ्रिक्वेन्सी वाढवण्याबाबत विनंती',
    description: 'लोकल ट्रेनच्या नियमित वेळेचे व्यवस्थापन आणि फ्रिक्वेन्सी वाढवण्याची विनंती.',
    image: localTrainImage,
    url: '/badlapurkar_esign', // URL to redirect when clicked
  },
];

const SocialInitiativesPage = () => {
  const navigate = useNavigate();

  const handleCardClick = (url) => {
    navigate(url); // Redirect to the specified URL
  };

  return (
    <Container>
      <Typography variant="h4" align="center" gutterBottom>
        सामाजिक उपक्रम
      </Typography>
      <Grid container spacing={2}>
        {socialInitiatives.map((initiative) => (
          <Grid item xs={12} sm={6} md={4} key={initiative.id}>
            <Card>
              <CardActionArea onClick={() => handleCardClick(initiative.url)}>
                <CardMedia
                  component="img"
                  height="70%"
                  image={initiative.image}
                  alt={initiative.title}
                />
                <CardContent>
                  <Typography variant="h6">{initiative.title}</Typography>
                  <Typography variant="body2" color="text.secondary">
                    {initiative.description}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default SocialInitiativesPage;
