import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import APIURL from '../config';
import UserAddressForm from './UserAddressForm';
import {
  Container,
  Grid,
  Card,
  CardMedia,
  CardContent,
  Typography,
  Button,
  CircularProgress,
  Snackbar,
  Stack,
} from '@mui/material';

function CartPage() {
  const [cartItems, setCartItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [orderSuccess, setOrderSuccess] = useState(false);
  const [orderError, setOrderError] = useState('');
  const [showAddressForm, setShowAddressForm] = useState(false);
  const [addressUpdated, setAddressUpdated] = useState(false);
  const navigate = useNavigate();

  const userId = localStorage.getItem('userId');

  useEffect(() => {
    const fetchCart = async () => {
      if (!userId) {
        navigate('/login');
        return;
      }

      try {
        const response = await axios.get(`${APIURL}/cart/${userId}`, {
          headers: { Authorization: 'ubpatil' },
        });
        setCartItems(response.data);
      } catch (error) {
        console.error('Error fetching cart:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchCart();
  }, [userId, navigate]);

  const handleClose = () => {
    navigate('/');
  };

  const handleRemoveFromCart = async (itemId) => {
    if (!userId) {
      navigate('/login');
      return;
    }

    try {
      await axios.delete(`${APIURL}/cart/${userId}/${itemId}`, {
        headers: { Authorization: 'ubpatil' },
      });
      setCartItems((prevItems) => prevItems.filter((item) => item.cartid !== itemId));
    } catch (error) {
      console.error('Error removing item from cart:', error);
    }
  };

  const handleAddressUpdate = () => {
    setAddressUpdated(true);
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Container maxWidth="sm" className="cart-page-container">
      <Typography variant="h4" align="center" gutterBottom>Your Cart</Typography>
      <Button onClick={handleClose} variant="outlined" className="cart-btn cart-btn-back">Go Back</Button>

      <Snackbar
        open={orderSuccess}
        onClose={() => setOrderSuccess(false)}
        message="Enquiry sent successfully!"
        autoHideDuration={6000}
      />

      {orderError && (
        <Typography color="error" variant="body1">{orderError}</Typography>
      )}

      {cartItems.length === 0 ? (
        <div className="cart-empty" style={{ textAlign: 'center' }}>
          <img src="https://via.placeholder.com/100" alt="Empty Cart" />
          <Typography variant="h6">Your cart is empty.</Typography>
          <Typography variant="body1">Add items to your cart to proceed with the checkout.</Typography>
        </div>
      ) : (
        <Grid container spacing={2}>
          {cartItems.map((item) => (
            <Grid item xs={12} sm={6} md={4} key={item.cartid}>
              <Card>
                <Zoom>
                  <CardMedia
                    component="img"
                    image={`${APIURL}/Images/${item.product_id}.jpg`}
                    alt={item.title}
                    sx={{ height: { xs: 150, sm: 200, md: 250 }, objectFit: 'cover' }} // Responsive image size
                  />
                </Zoom>
                <CardContent>
                  <Typography variant="h6">{item.title}</Typography>
                  <Typography variant="body2">Price: INR {item.price}</Typography>
                  <Typography variant="body2">Quantity: {item.quantity}</Typography>
                  <Typography variant="body2"><strong>Size:</strong> {item.size}</Typography>
                  <Typography variant="body2"><strong>Material:</strong> {item.material}</Typography>
                  <Typography variant="body2"><strong>Features:</strong> {item.features}</Typography>
                  <Button onClick={() => handleRemoveFromCart(item.cartid)} variant="contained" color="error">Remove</Button>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}

      <Stack spacing={2} alignItems="center" sx={{ mt: 2 }}>
        {!showAddressForm ? (
          <Button onClick={() => setShowAddressForm(true)} variant="contained" color="primary">Send Enquiry</Button>
        ) : (
          <div className="address-form-container">
            <UserAddressForm onAddressUpdate={handleAddressUpdate} />
          </div>
        )}
      </Stack>
    </Container>
  );
}

export default CartPage;
