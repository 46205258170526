import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Container, Typography, Box } from '@mui/material';

const AdminMenu = () => {
  return (
    <Container maxWidth="sm" style={{ textAlign: 'center', marginTop: '50px' }}>
      <Typography variant="h4" gutterBottom>Admin Menu</Typography>

      <Box mt={4}>
        <Link to="/addProducts" style={{ textDecoration: 'none' }}>
          <Button variant="contained" color="primary" fullWidth style={{ marginBottom: '20px' }}>
            Add Product
          </Button>
        </Link>
        <Link to="/admin/view-my-orders" style={{ textDecoration: 'none' }}>
          <Button variant="contained" color="secondary" fullWidth>
            View Orders
          </Button>
        </Link>
      </Box>
    </Container>
  );
};

export default AdminMenu;
