import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const UploadP = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate('/upload'); // Redirect to /upload
  }, [navigate]);

  return null; // Or a loading indicator
};

export default UploadP;
