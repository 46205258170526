import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import logo from '../Images/logo.png';
import cartImage from '../Images/cart.png';
import logoutImage from '../Images/logout.png';
import loginImage from '../Images/login.png'; // Import the login image
import settingsImage from '../Images/setting.png';
import adminImage from '../Images/admin.png';
import aboutme from '../Images/aboutme.png';
import contestImage from '../Images/contest.png';
import uploadImage from '../Images/upload.png';
import participateImage from '../Images/peoples.png';
import productsImage from '../Images/products.png';
import { AppBar, Toolbar, IconButton, Menu, MenuItem, useMediaQuery } from '@mui/material'; 
import MenuIcon from '@mui/icons-material/Menu';
import './Navbar.css'; // Import CSS file

function Navbar() {
  const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem('authToken'));
  const [isAdmin, setIsAdmin] = useState(localStorage.getItem('isAdmin') === 'true');
  const [anchorEl, setAnchorEl] = useState(null);
  const location = useLocation();
  const isMobile = useMediaQuery('(max-width:600px)');

  useEffect(() => {
    const handleStorageChange = () => {
      setIsLoggedIn(!!localStorage.getItem('authToken'));
      setIsAdmin(localStorage.getItem('isAdmin') === 'true');
    };

    window.addEventListener('storage', handleStorageChange);
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const getNavLinkClass = (path) => {
    return location.pathname === path ? 'active' : '';
  };

  return (
    <AppBar position="static" style={{ backgroundColor: 'rgb(213 161 30)' }}>
      <Toolbar>
        <Link to="/" style={{ textDecoration: 'none' }}>
          <img src={logo} alt="Logo" />
        </Link>
        <div style={{ flexGrow: 1 }} />

        {isMobile ? (
          <>
            <IconButton edge="end" color="inherit" onClick={handleMenuClick}>
              <MenuIcon />
            </IconButton>
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
              <MenuItem onClick={handleMenuClose}>
                <Link to="/products" style={{ textDecoration: 'none', color: 'inherit' }}>
                  <img src={productsImage} alt="Products" className="menu-image" />
                </Link>
              </MenuItem>
              <MenuItem onClick={handleMenuClose}>
                <Link to="/contest" style={{ textDecoration: 'none', color: 'inherit' }}>
                  <img src={contestImage} alt="Contest" className="menu-image" />
                </Link>
              </MenuItem>
              <MenuItem onClick={handleMenuClose}>
                <Link to="/about" style={{ textDecoration: 'none', color: 'inherit' }}>
                  <img src={aboutme} alt="About Us" className="menu-image" />
                </Link>
              </MenuItem>
              {isAdmin && (
                <MenuItem onClick={handleMenuClose}>
                  <Link to="/admin/dashboard" style={{ textDecoration: 'none', color: 'inherit' }}>
                    <img src={adminImage} alt="Admin Panel" className="menu-image" />
                  </Link>
                </MenuItem>
              )}
              {!isLoggedIn ? (
                <MenuItem onClick={handleMenuClose}>
                  <Link to="/login" style={{ textDecoration: 'none', color: 'inherit' }}>
                    <img src={loginImage} alt="Login" className="menu-image" /> {/* Change here */}
                  </Link>
                </MenuItem>
              ) : (
                <>
                  <MenuItem onClick={handleMenuClose}>
                    <Link to="/adminmenu" style={{ textDecoration: 'none', color: 'inherit' }}>
                      <img src={settingsImage} alt="Settings" className="menu-image" />
                    </Link>
                  </MenuItem>
                  <MenuItem onClick={handleMenuClose}>
                    <Link to="/logout" style={{ textDecoration: 'none', color: 'inherit' }}>
                      <img src={logoutImage} alt="Logout" className="menu-image" />
                    </Link>
                  </MenuItem>
                </>
              )}
            </Menu>
          </>
        ) : (
          <div style={{ display: 'flex', gap: '10px' }}>
            <Link to="/products">
              <img src={productsImage} alt="Products" className="menu-image" />
            </Link>
            <Link to="/contest">
              <img src={contestImage} alt="Contest" className="menu-image" />
            </Link>
            <Link to="/about">
              <img src={aboutme} alt="About Us" className="menu-image" />
            </Link>
            {isAdmin && (
              <Link to="/admin/dashboard">
                <img src={adminImage} alt="Admin Panel" className="menu-image" />
              </Link>
            )}
            {!isLoggedIn ? (
              <Link to="/login">
                <img src={loginImage} alt="Login/Signup" className="menu-image" /> {/* Change here */}
              </Link>
            ) : (
              <>
                <Link to="/adminmenu">
                  <img src={settingsImage} alt="Settings" className="menu-image" />
                </Link>
                <Link to="/logout">
                  <img src={logoutImage} alt="Logout" className="menu-image" />
                </Link>
              </>
            )}
          </div>
        )}
      </Toolbar>
    </AppBar>
  );
}

export default Navbar;
