import React, { useState } from 'react';
import html2canvas from 'html2canvas';
import { Box, Button, TextField, Typography, IconButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import { PhotoCamera } from '@mui/icons-material';

const HappyDasaraBanner = () => {
  const [name, setName] = useState('');
  const [location, setLocation] = useState('');
  const [image, setImage] = useState(null);
  const [bannerPreview, setBannerPreview] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Handle image upload
  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setImage(reader.result);
    };
    reader.readAsDataURL(file);
  };

  // Preview the banner
  const handlePreview = () => {
    setBannerPreview(true);
  };

  // Download the banner as JPG
  const handleDownload = () => {
    const bannerElement = document.getElementById('banner');
    html2canvas(bannerElement).then((canvas) => {
      const link = document.createElement('a');
      link.download = 'happy_dasara_banner.jpg';
      link.href = canvas.toDataURL('image/jpeg');
      link.click();
    });
  };

  return (
    <Box sx={{ textAlign: 'center', padding: '20px', maxWidth: '600px', margin: '0 auto' }}>
      <Typography variant="h4" gutterBottom>
        Create Your Happy Dasara Banner
      </Typography>

      {/* User Inputs */}
      <TextField
        label="Enter Your Name"
        value={name}
        onChange={(e) => setName(e.target.value)}
        fullWidth
        margin="normal"
        variant="outlined"
      />
      <TextField
        label="Enter Your Location"
        value={location}
        onChange={(e) => setLocation(e.target.value)}
        fullWidth
        margin="normal"
        variant="outlined"
      />

      {/* Image Upload with Icon Button */}
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '20px 0' }}>
        <Button
          variant="contained"
          component="label"
          startIcon={<PhotoCamera />}
        >
          Upload Image
          <input type="file" hidden onChange={handleImageUpload} accept="image/*" />
        </Button>
      </Box>

      {/* Preview Button */}
      <Button
        variant="contained"
        color="primary"
        onClick={handlePreview}
        sx={{ marginBottom: '20px' }}
      >
        Preview Banner
      </Button>

      {/* Display the Banner Preview */}
      {bannerPreview && (
        <Box
          id="banner"
          sx={{
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
            width: isMobile ? '100%' : '600px',
            height: '400px',
            backgroundColor: '#f0f0f0',
            margin: '20px auto',
            borderRadius: '8px',
            overflow: 'hidden',
            position: 'relative',
          }}
        >
          {/* Image Section */}
          <Box
            sx={{
              flex: 1,
              backgroundImage: `url(${image})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              height: '100%',
              borderRight: isMobile ? 'none' : '2px solid #FFA500',
            }}
          />
          
          {/* Text Section */}
          <Box
            sx={{
              flex: 1,
              padding: '20px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'flex-start',
              backgroundColor: 'rgba(255, 255, 255, 0.8)',
            }}
          >
            <Typography
              variant={isMobile ? 'h4' : 'h2'}
              sx={{
                fontFamily: '"Dancing Script", cursive',
                color: '#FFA500',
                marginBottom: '10px',
                textShadow: '1px 1px 2px rgba(0, 0, 0, 0.5)',
              }}
            >
              Happy Dasara!
            </Typography>

            {/* Dasara Quote */}
            <Typography
              variant="body1"
              sx={{
                backgroundColor: 'rgba(0, 0, 0, 0.1)',
                padding: '10px',
                fontStyle: 'italic',
                color: '#333',
                textShadow: '1px 1px 2px rgba(255, 255, 255, 0.7)',
              }}
            >
              "May this Dasara bring peace, prosperity, and happiness to you and your family."
            </Typography>

            {/* Name and Location Display */}
            <Typography
              variant="h6"
              sx={{
                marginTop: '20px',
                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                padding: '5px',
                fontWeight: 'bold',
                color: '#FFA500',
                textShadow: '1px 1px 2px rgba(0, 0, 0, 0.5)',
              }}
            >
              {name ? `From ${name}` : ''} {location ? ` ${location}` : ''}
            </Typography>

             
          </Box>
        </Box>
      )}

      {/* Download Button */}
      {bannerPreview && (
        <Button
          variant="contained"
          color="success"
          onClick={handleDownload}
          sx={{
            padding: '10px 20px',
            fontSize: '18px',
            cursor: 'pointer',
            backgroundColor: '#FFA500',
            color: 'white',
            borderRadius: '5px',
            marginTop: '20px',
          }}
        >
          Download as JPG
        </Button>
      )}
    </Box>
  );
};

export default HappyDasaraBanner;
