import React, { useState, useEffect } from 'react';
import axios from 'axios';
import APIURL from '../config';
import { useNavigate } from 'react-router-dom';
import {
  TextField,
  Select,
  MenuItem,
  Button,
  FormControl,
  InputLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';

function UserAddressForm() {
  const [userData, setUserData] = useState({});
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedState, setSelectedState] = useState('');
  const [selectedCity, setSelectedCity] = useState('');
  const [address, setAddress] = useState('');
  const [pincode, setPincode] = useState('');
  const [orderSuccess, setOrderSuccess] = useState(false);
  const [errors, setErrors] = useState({});
  const userId = localStorage.getItem('userId');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await axios.get(`${APIURL}/countries`, {
          headers: { 'Authorization': 'ubpatil' }
        });
        setCountries(response.data);
      } catch (error) {
        console.error('Error fetching countries:', error);
      }
    };

    fetchCountries();
  }, []);

  useEffect(() => {
    const fetchStates = async () => {
      if (selectedCountry) {
        try {
          const response = await axios.get(`${APIURL}/states`, {
            headers: { 'Authorization': 'ubpatil' },
            params: { countryId: selectedCountry }
          });
          setStates(response.data);
          setSelectedState(''); // Reset state
          setCities([]); // Reset cities
        } catch (error) {
          console.error('Error fetching states:', error);
        }
      }
    };

    fetchStates();
  }, [selectedCountry]);

  useEffect(() => {
    const fetchCities = async () => {
      if (selectedState) {
        try {
          const response = await axios.get(`${APIURL}/cities`, {
            headers: { 'Authorization': 'ubpatil' },
            params: { stateId: selectedState }
          });
          setCities(response.data);
        } catch (error) {
          console.error('Error fetching cities:', error);
        }
      }
    };

    fetchCities();
  }, [selectedState]);

  useEffect(() => {
    const fetchUserData = async () => {
      if (userId) {
        try {
          const response = await axios.get(`${APIURL}/user/${userId}`, {
            headers: { 'Authorization': 'ubpatil' }
          });
          const { country, state, city, address, pinCode } = response.data;

          setUserData(response.data);
          setAddress(address || '');
          setPincode(pinCode || '');

          setSelectedCountry(country || '');
          if (country) {
            const statesResponse = await axios.get(`${APIURL}/states`, {
              headers: { 'Authorization': 'ubpatil' },
              params: { countryId: country }
            });
            setStates(statesResponse.data);

            setSelectedState(state || '');
            if (state) {
              const citiesResponse = await axios.get(`${APIURL}/cities`, {
                headers: { 'Authorization': 'ubpatil' },
                params: { stateId: state }
              });
              setCities(citiesResponse.data);
              setSelectedCity(city || '');
            }
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      }
    };

    fetchUserData();
  }, [userId]);

  const validateFields = () => {
    const newErrors = {};
    if (!address.trim()) newErrors.address = 'Address is required.';
    if (!pincode.trim()) newErrors.pincode = 'Pincode is required.';
    if (!selectedCountry) newErrors.country = 'Country is required.';
    if (!selectedState) newErrors.state = 'State is required.';
    if (!selectedCity) newErrors.city = 'City is required.';

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleAddressUpdate = async () => {
    try {
      const addressData = {
        userId,
        address,
        pinCode: pincode,
        city: selectedCity,
        state: selectedState,
        country: selectedCountry
      };

      await axios.put(`${APIURL}/update-address`, addressData, {
        headers: { 'Authorization': 'ubpatil' }
      });
    } catch (error) {
      console.error('Error updating address:', error);
      alert('Failed to update address.');
    }
  };

  const handleOrderConfirmation = async () => {
    if (!validateFields()) return;

    try {
      await handleAddressUpdate(); // Update address first

      await axios.post(`${APIURL}/confirm-order`, { userId }, {
        headers: { 'Authorization': 'ubpatil' }
      });

      // Show success modal
      setOrderSuccess(true);
    } catch (error) {
      console.error('Error confirming order:', error);
      alert('Failed to confirm Enquiry.');
    }
  };

  const handleClose = () => {
    setOrderSuccess(false); // Close modal
    navigate('/products'); // Redirect to /products
  };

  return (
    <div className="address-form-container">
      <h2>Update Address</h2>
      <form className="address-form" onSubmit={(e) => e.preventDefault()}>
        <TextField
          label="Address"
          variant="outlined"
          fullWidth
          value={address}
          onChange={(e) => setAddress(e.target.value)}
          error={!!errors.address}
          helperText={errors.address}
          margin="normal"
        />
        <TextField
          label="Pincode"
          variant="outlined"
          fullWidth
          value={pincode}
          onChange={(e) => setPincode(e.target.value)}
          error={!!errors.pincode}
          helperText={errors.pincode}
          margin="normal"
        />
        <FormControl fullWidth margin="normal" error={!!errors.country}>
          <InputLabel>Country</InputLabel>
          <Select
            value={selectedCountry}
            onChange={(e) => setSelectedCountry(e.target.value)}
          >
            <MenuItem value=""><em>Select Country</em></MenuItem>
            {countries.map(c => (
              <MenuItem key={c.id} value={c.id}>{c.name}</MenuItem>
            ))}
          </Select>
          {errors.country && <p className="error">{errors.country}</p>}
        </FormControl>
        <FormControl fullWidth margin="normal" error={!!errors.state} disabled={!selectedCountry}>
          <InputLabel>State</InputLabel>
          <Select
            value={selectedState}
            onChange={(e) => setSelectedState(e.target.value)}
          >
            <MenuItem value=""><em>Select State</em></MenuItem>
            {states.map(s => (
              <MenuItem key={s.id} value={s.id}>{s.name}</MenuItem>
            ))}
          </Select>
          {errors.state && <p className="error">{errors.state}</p>}
        </FormControl>
        <FormControl fullWidth margin="normal" error={!!errors.city} disabled={!selectedState}>
          <InputLabel>City</InputLabel>
          <Select
            value={selectedCity}
            onChange={(e) => setSelectedCity(e.target.value)}
          >
            <MenuItem value=""><em>Select City</em></MenuItem>
            {cities.map(c => (
              <MenuItem key={c.id} value={c.id}>{c.name}</MenuItem>
            ))}
          </Select>
          {errors.city && <p className="error">{errors.city}</p>}
        </FormControl>
        <Button variant="contained" color="primary" onClick={handleOrderConfirmation}>
          Confirm Enquiry
        </Button>
      </form>

      {/* Modal for order success */}
      <Dialog open={orderSuccess} onClose={handleClose}>
        <DialogTitle>Success</DialogTitle>
        <DialogContent>
          <p style={{ color: 'green' }}>
            Your enquiry has been placed. Thank you for contacting us.
          </p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default UserAddressForm;
