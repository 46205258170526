import React from 'react';
import { Container, Typography, Card, CardMedia, CardContent } from '@mui/material';
import { Link } from 'react-router-dom';
import contestImage from '../Images/peoples.png';  // Contest image
import uploadImage from '../Images/upload.png';  // Upload image
import winnerImage from '../Images/winner.png';  // Winners image

const ContestPage = () => {
  return (
    <Container>
      {/* Contest Description */}
      <Typography variant="h4" align="center" gutterBottom>
        Yuvalink Community Contests
      </Typography>
      <Typography variant="body1" align="center" paragraph>
        Yuvalink organizes various contests and activities to bring people together and foster creativity, innovation, and community bonding. Whether it's a creative contest, a fun activity, or a cultural event, these initiatives are designed to engage everyone and build stronger connections.
      </Typography>

      {/* Cards with Links */}
      

      <Link to="/participants" style={{ textDecoration: 'none' }}>
        <Card sx={{ mb: 3, cursor: 'pointer' }}>
          <CardMedia
            component="img"
            sx={{ height: 128, width: 128, margin: '0 auto' }} // Centered image with 128px height and width
            image={contestImage}
            alt="View Participants"
          />
          <CardContent>
            <Typography variant="h5">Meet the Participants</Typography>
            <Typography variant="body2" color="text.secondary">
              Explore the diverse contributions from participants across various events and get inspired by the talent and creativity within our community.
            </Typography>
          </CardContent>
        </Card>
      </Link>

      {/* New Winners Card */}
      <Link to="/winnersMenu" style={{ textDecoration: 'none' }}>
        <Card sx={{ mb: 3, cursor: 'pointer' }}>
          <CardMedia
            component="img"
            sx={{ height: 128, width: 128, margin: '0 auto' }} // Centered image with 128px height and width
            image={winnerImage}
            alt="View Winners"
          />
          <CardContent>
            <Typography variant="h5">View Winners</Typography>
            <Typography variant="body2" color="text.secondary">
              Celebrate the success of our contest winners and explore their amazing contributions to the community.
            </Typography>
          </CardContent>
        </Card>
      </Link>
      
    </Container>
  );
};

export default ContestPage;
