import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
  Container,
  Grid,
  Alert,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Pagination,
} from '@mui/material';
import API_URL from '../../config.js';

const ProductList = () => {
  // State for adding a product
  const [title, setTitle] = useState('');
  const [idolName, setIdolName] = useState('');
  const [price, setPrice] = useState('');
  const [size, setSize] = useState('');
  const [material, setMaterial] = useState('');
  const [features, setFeatures] = useState('');
  const [isActive, setIsActive] = useState(true);
  const [image, setImage] = useState(null);
  const [alert, setAlert] = useState(null);

  // State for product listing with pagination
  const [products, setProducts] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [search, setSearch] = useState(''); // New state for search text

  // Fetch paginated products
  useEffect(() => {
    fetchProducts(page, search); // Pass search text to fetchProducts
  }, [page, search]);

  const fetchProducts = async (page, search) => {
    try {
      const response = await axios.get(`${API_URL}/productsListAdmin?page=${page}&search=${search}`);
      if (response.data && response.data.products) {
        setProducts(response.data.products);
        setTotalPages(response.data.totalPages);
      } else {
        setProducts([]); // Set products to empty array if data is not available
      }
    } catch (error) {
      console.error('Failed to fetch products:', error);
      setProducts([]); // Handle error by setting an empty array
    }
  };

  const handleAddProduct = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('title', title);
    formData.append('idolName', idolName);
    formData.append('price', price);
    formData.append('size', size);
    formData.append('material', material);
    formData.append('features', features);
    formData.append('isActive', isActive ? 1 : 0);
    if (image) {
      formData.append('image', image);
    }

    try {
      await axios.post(`${API_URL}/productInserts`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': 'ubpatil',
        },
      });
      setAlert({ type: 'success', text: 'Product added successfully!' });
      // Reset form fields
      setTitle('');
      setIdolName('');
      setPrice('');
      setSize('');
      setMaterial('');
      setFeatures('');
      setIsActive(true);
      setImage(null);
      fetchProducts(page, search); // Refresh product list after adding a new product
    } catch (error) {
      setAlert({ type: 'error', text: 'Failed to add product. Please try again later.' });
    }
  };

  const handleToggleActive = async (productId, isActive) => {
    try {
      await axios.post(`${API_URL}/toggleProductActive`, { productId, isActive });
      fetchProducts(page, search); // Refresh product list after updating status
    } catch (error) {
      console.error('Failed to update product status:', error);
    }
  };

  return (
    <Container maxWidth="md" style={{ marginTop: '20px' }}>
      <Typography variant="h4" gutterBottom>Add Product</Typography>
      {alert && <Alert severity={alert.type === 'success' ? 'success' : 'error'}>{alert.text}</Alert>}
      
      <Typography variant="h5" gutterBottom style={{ marginTop: '40px' }}>Product List</Typography>
      <TextField
        label="Search"
        fullWidth
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        style={{ marginBottom: '20px' }}
      />
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Title</TableCell>
              <TableCell>Price</TableCell>
              <TableCell>Size</TableCell>
              <TableCell>Active</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
  {products && products.length > 0 ? (
    products.map((product) => (
      <TableRow key={product.id}>
        <TableCell>{product.title}</TableCell>
        <TableCell>{product.price}</TableCell>
        <TableCell>{product.size}</TableCell>
        <TableCell>{product.isActive ? 'Yes' : 'No'}</TableCell>
        <TableCell>
                    <Button
                      variant="contained"
                      color={product.isActive ? 'secondary' : 'primary'}
                      onClick={() => handleToggleActive(product.id, !product.isActive)}
                    >
                      {product.isActive ? 'Deactivate' : 'Activate'}
                    </Button>
                  </TableCell> 
      </TableRow>
    ))
  ) : (
    <TableRow>
      <TableCell colSpan={5}>No products available.</TableCell>
    </TableRow>
  )}
</TableBody>

        </Table>
        <Pagination
          count={totalPages}
          page={page}
          onChange={(event, value) => setPage(value)}
          style={{ marginTop: '20px', justifyContent: 'center', display: 'flex' }}
        />
      </TableContainer>
    </Container>
  );
};

export default ProductList;
