import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  Skeleton,
  Modal,
  TextField,
  Button,
} from '@mui/material';
import APIURL from '../config'; // Import the global API URL
import callImage from '../Images/call.png'; // Import the call image
import emailImage from '../Images/email.png'; // Import the email image

const BusinessDirectory = () => {
  const [businesses, setBusinesses] = useState([]);
  const [filteredBusinesses, setFilteredBusinesses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [otp, setOtp] = useState('');
  const [generatedOtp, setGeneratedOtp] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [leadModalOpen, setLeadModalOpen] = useState(false);
  const [otpVerificationMessage, setOtpVerificationMessage] = useState('');

  // Lead data state
  const [leadData, setLeadData] = useState({
    name: '',
    mobile: '',
    email: '',
    location: '',
    locality: '',
    description: '',
    businessId: null,
    categoryId: null,
  });

  // States for filters
  const [selectedService, setSelectedService] = useState('');
  const [selectedLocation, setSelectedLocation] = useState('');
  const [selectedLocality, setSelectedLocality] = useState('');
  const [services, setServices] = useState([]);

  const fetchBusinesses = async () => {
    try {
      const response = await axios.get(`${APIURL}/businesseslist`, {
        headers: { Authorization: 'ubpatil' },
      });
      setBusinesses(response.data);
      setFilteredBusinesses(response.data);
      const uniqueServices = [...new Set(response.data.map(business => business.bname))];
      setServices(uniqueServices);
    } catch (error) {
      setErrorMessage('Failed to fetch businesses. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const handleFilterChange = () => {
    const filtered = businesses.filter((business) => {
      const matchesService =
        selectedService === '' || business.bname.toLowerCase().includes(selectedService.toLowerCase());
      const matchesLocation =
        selectedLocation === '' || business.location.toLowerCase().includes(selectedLocation.toLowerCase());
      const matchesLocality =
        selectedLocality === '' || business.locality.toLowerCase().includes(selectedLocality.toLowerCase());
      return matchesService && matchesLocation && matchesLocality;
    });
    setFilteredBusinesses(filtered);
  };

  useEffect(() => {
    fetchBusinesses();
  }, []);

  useEffect(() => {
    handleFilterChange();
  }, [selectedService, selectedLocation, selectedLocality]);

  const handlePhoneClick = (phone, business) => {
    const generatedOtp = Math.floor(100000 + Math.random() * 900000);
    setGeneratedOtp(generatedOtp);
    setLeadModalOpen(true);

    const message = `Your Verification number is: ${generatedOtp}`;
    const whatsappUrl = `https://wa.me/?text=${encodeURIComponent(message)}`;

    setLeadData((prev) => ({
      ...prev,
      businessId: business.id,
      categoryId: business.bname,
    }));
  };

  const handleOtpSubmit = () => {
    setOtpVerificationMessage('Verified successfully!');
    setOpenModal(false);
    setLeadModalOpen(true);
  };

  const handleLeadSubmit = async () => {
    // Email validation
    const isValidEmail = (email) => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    };

    // Mobile number validation
    const isValidMobile = (mobile) => {
      const mobileRegex = /^[0-9]{10}$/;
      return mobileRegex.test(mobile);
    };

    // Validate email and mobile number
    if (!isValidEmail(leadData.email)) {
      alert('Please enter a valid email address.');
      return;
    }

    if (!isValidMobile(leadData.mobile)) {
      alert('Please enter a valid mobile number (10 digits).');
      return;
    }

    try {
      const response = await axios.post(`${APIURL}/submit-lead`, leadData, {
        headers: { Authorization: 'ubpatil' },
      });
      alert(response.data.message);
      setLeadModalOpen(false);
      setLeadData({
        name: '',
        mobile: '',
        email: '',
        location: '',
        locality: '',
        description: '',
        businessId: null,
        categoryId: null,
      });
    } catch (error) {
      console.error('Error submitting lead:', error);
      alert('Failed to submit lead. Please try again.');
    }
  };

  const maskPhone = (phone) => (phone.length > 2 ? `${phone.slice(0, 2)}****` : phone);
  const maskEmail = (email) => {
    const [name, domain] = email.split('@');
    return `${name.slice(0, 2)}****@${domain}`;
  };

  return (
    <Container>
      {/* Lead Form Modal */}
     <Modal open={leadModalOpen} onClose={() => setLeadModalOpen(false)}>
        <div
          style={{
            padding: '20px',
            background: 'white',
            borderRadius: '5px',
            margin: '100px auto',
            maxWidth: '400px',
          }}
        >
          <Typography variant="h6">Lead Submission</Typography>

          <TextField
            label="Name"
            value={leadData.name}
            onChange={(e) => setLeadData({ ...leadData, name: e.target.value })}
            style={{ marginBottom: '10px', width: '100%' }}
            inputProps={{ maxLength: 50 }}
          />

          <TextField
            label="Mobile"
            value={leadData.mobile}
            onChange={(e) => setLeadData({ ...leadData, mobile: e.target.value })}
            style={{ marginBottom: '10px', width: '100%' }}
            inputProps={{ maxLength: 15 }}
          />

          <TextField
            label="Email"
            value={leadData.email}
            onChange={(e) => setLeadData({ ...leadData, email: e.target.value })}
            style={{ marginBottom: '10px', width: '100%' }}
            inputProps={{ maxLength: 50 }}
          />

          {/* Service Location Input */}
          <TextField
            label="Location" // Service Location
            value={leadData.location}
            onChange={(e) => setLeadData({ ...leadData, location: e.target.value })}
            style={{ marginBottom: '10px', width: '100%' }}
            inputProps={{ maxLength: 50 }}
          />

          {/* Locality Input */}
          <TextField
            label="Locality"
            value={leadData.locality}
            onChange={(e) => setLeadData({ ...leadData, locality: e.target.value })}
            style={{ marginBottom: '10px', width: '100%' }}
            inputProps={{ maxLength: 100 }}
          />

          <TextField
            label="Description"
            value={leadData.description}
            onChange={(e) => setLeadData({ ...leadData, description: e.target.value })}
            style={{ marginBottom: '10px', width: '100%' }}
            inputProps={{ maxLength: 200 }}
          />

          <Button variant="contained" onClick={handleLeadSubmit}>
            Submit Lead
          </Button>
        </div>
      </Modal>

      <Typography
  variant="h5"
  gutterBottom
  align="center"
  style={{
    color: '#rgb(178 63 181)',          // Text color
    fontWeight: 'bold',         // Bold font style
    margin: '20px 0',           // Spacing
    padding: '10px 20px',       // Padding for a balanced look
    backgroundColor: 'rgb(234 161 77)', // Light background color
    border: '2px solid #3f51b5',// Border color and thickness
    borderRadius: '8px',        // Rounded corners for a polished look
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)' // Subtle shadow effect
  }}
>
  Local Business Network
</Typography>


      {/* Filter Section */}
      <Grid container spacing={2} style={{ marginLeft: '2px', marginBottom: '30px',width:'100%', backgroundColor: 'rgb(251 228 156)', padding: '20px', borderRadius: '8px' }}>
      <Grid item xs={12}>
          <TextField
            variant="outlined"
            select
            value={selectedService}
            onChange={(e) => setSelectedService(e.target.value)}
            fullWidth
            SelectProps={{
              native: true,
            }}
            style={{ marginBottom: '10px' }}
          >
            <option value="">All Services</option>
            {services.map((service, index) => (
              <option key={index} value={service}>
                {service}
              </option>
            ))}
          </TextField>
        </Grid>

        <Grid item xs={12}>
          <TextField
            label="Search Location"
            variant="outlined"
            value={selectedLocation}
            onChange={(e) => setSelectedLocation(e.target.value)}
            fullWidth
            style={{ marginBottom: '10px' }}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            label="Search Locality"
            variant="outlined"
            value={selectedLocality}
            onChange={(e) => setSelectedLocality(e.target.value)}
            fullWidth
            style={{ marginBottom: '10px' }}
          />
        </Grid>
      </Grid>

      {/* Businesses List */}
      <Grid container spacing={2}>
        {loading ? (
          [...Array(6)].map((_, index) => (
            <Grid item xs={12} key={index}>
              <Card>
                <CardContent>
                  <Skeleton variant="text" />
                  <Skeleton variant="text" />
                  <Skeleton variant="rectangular" height={140} />
                </CardContent>
              </Card>
            </Grid>
          ))
        ) : (
          filteredBusinesses.map((business) => (
            <Grid item xs={12} key={business.id}>
              <Card style={{ backgroundColor: 'rgb(245 221 103)', border: '1px solid #3f51b5' }}>
                <CardContent>
                  <Typography variant="h5" style={{ fontWeight: 'bold', color: '#3f51b5', marginBottom: '10px' }}>
                    {business.business_name}
                  </Typography>
                  <Typography variant="h6" style={{ color: '#333' }}>
                    {business.bname}
                  </Typography>
                  <Typography variant="body1" style={{ marginBottom: '10px' }}>
                    Location: {business.location}
                  </Typography>

                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img src={callImage} alt="call icon" style={{ marginRight: '5px', width: '20px' }} />
                    <Typography variant="body2" style={{ color: '#333' }}>
                      {maskPhone(business.phone)}
                    </Typography>
                  </div>

                  <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                    <img src={emailImage} alt="email icon" style={{ marginRight: '5px', width: '20px' }} />
                    <Typography variant="body2" style={{ color: '#333' }}>
                      {maskEmail(business.email)}
                    </Typography>
                  </div>

                  <Button
                    variant="contained"
                    style={{ backgroundColor: '#3f51b5', color: '#fff', marginTop: '10px' }}
                    onClick={() => handlePhoneClick(business.phone, business)}
                  >
                    Contact Now
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          ))
        )}
      </Grid>

      {/* OTP Modal */}
      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <div
          style={{
            padding: '20px',
            background: '#f5f5f5',
            borderRadius: '5px',
            margin: '100px auto',
            maxWidth: '400px',
          }}
        >
          <Typography variant="h6" style={{ fontWeight: 'bold', color: '#333' }}>
            Enter OTP
          </Typography>
          <TextField
            label="OTP"
            variant="outlined"
            fullWidth
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
            style={{ marginBottom: '10px' }}
          />
          <Button
            variant="contained"
            style={{ backgroundColor: '#3f51b5', color: '#fff', marginBottom: '10px' }}
            onClick={handleOtpSubmit}
          >
            Verify OTP
          </Button>
          <Typography variant="body2" color="textSecondary">
            {otpVerificationMessage}
          </Typography>
        </div>
      </Modal>
    </Container>
  );
};

export default BusinessDirectory;
