import React, { useEffect } from 'react';
import { Container, Grid, Card, CardMedia, CardContent, Typography } from '@mui/material';
import winner1 from '../Images/1.jpg'; 
import winner2 from '../Images/2.jpg'; 
import winner3 from '../Images/3.jpg'; 
import winner5 from '../Images/5.jpg';  
import winner6 from '../Images/6.jpg';  
import trophyImage from '../Images/trophy.png'; // Import the trophy image
import './WinnerPage.css';

const NavaratriWinnerPage = [
  {
    "id": 1,
    "name": "Kavita Dipak Patil",
    "image": winner1,
    "description": "Badlapur."
  },
  {
    "id": 2,
    "name": "Yugandhara Hitendra Zambare",
    "image": winner2,
    "description": ""
  },
  {
    "id": 3,
    "name": "सौ. प्रीति रविंद्र ब्राह्मणकर",
    "image": winner3,
    "description": ""
  },
  {
    "id": 4,
    "name": "Asawari and Vishakha",
    "image": winner5,
    "description": ""
  },
  {
    "id": 6,
    "name": "Smita Patil",
    "image": winner6,
    "description": ""
  }
];

const WinnerPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container className="container">
      <Typography variant="h4" align="center" className="title">
        Navratri Rangoli Contest 2024 Winners
      </Typography>
      <Typography variant="body1" align="center" className="description" paragraph>
        Welcome to the Navratri Rangoli Contest 2024! This initiative by Yuvalink brings our community together to showcase creativity and cultural spirit. Join us in celebrating the artistic talents of our participants and congratulations to the winners!
      </Typography>
      <Grid container spacing={2}>
        {NavaratriWinnerPage.map((winner) => (
          <Grid item xs={12} sm={6} md={4} key={winner.id} className="winnerCard">
            <Card>
              {/* Trophy overlay */}
              <img src={trophyImage} alt="Trophy" className="trophyOverlay" />
              
              <CardMedia
                component="img"
                className="winnerImage"
                image={winner.image}
                alt={winner.name}
              />
              <CardContent>
                <Typography variant="h5">{winner.name}</Typography>
                <Typography variant="body2" color="text.secondary">
                  {winner.description}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default WinnerPage;
