import React, { useState, useEffect } from 'react';
import axios from 'axios'; 
import APIURL from '../../config.js';
import ImageModal from './ImageModal'; // Import the ImageModal component
import Breadcrumbs from '../Breadcrumbs'; // Import the Breadcrumbs component
import {
    Table, TableBody, TableCell, TableHead, TableRow,
    Button, Select, MenuItem, TextField, Dialog, DialogContent, DialogActions, Grid
} from '@mui/material';

function ViewOrders() {
    const [orders, setOrders] = useState([]);
    const [orderItems, setOrderItems] = useState([]);
    const [users, setUsers] = useState([]);
    const [products, setProducts] = useState([]);
    const [filteredOrders, setFilteredOrders] = useState([]);
    const [sortOrder, setSortOrder] = useState('asc');
    const [filters, setFilters] = useState({ status: '', search: '' });
    const [modalImage, setModalImage] = useState(null); // State for modal image
    const [modalOpen, setModalOpen] = useState(false); // State for modal visibility
    const [expandedOrders, setExpandedOrders] = useState(new Set()); // State for expanded rows

    const currentUserId = localStorage.getItem('userId');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [ordersRes, orderItemsRes, usersRes, productsRes] = await Promise.all([
                    axios.get(`${APIURL}/myorders`, { headers: { 'Authorization': 'ubpatil' }, params: { userId: currentUserId } }),
                    axios.get(`${APIURL}/order-items`, { headers: { 'Authorization': 'ubpatil' } }),
                    axios.get(`${APIURL}/users`, { headers: { 'Authorization': 'ubpatil' } }),
                    axios.get(`${APIURL}/products`, { headers: { 'Authorization': 'ubpatil' } })
                ]);

                setOrders(ordersRes.data);
                setOrderItems(orderItemsRes.data);
                setUsers(usersRes.data);
                setProducts(productsRes.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [currentUserId]);

    useEffect(() => {
        const applyFilters = () => {
            let filtered = orders.map(order => {
                const items = orderItems.filter(item => item.order_id === order.order_id);
                const orderDetails = items.map(item => {
                    const product = products.find(p => p.id === item.product_id);
                    return {
                        ...item,
                        productName: product ? product.title : 'Unknown',
                        productImage: product ? product.image : 'default.jpg'
                    };
                });
                return {
                    ...order,
                    items: orderDetails
                };
            });

            if (filters.status) {
                filtered = filtered.filter(order => order.status === filters.status);
            }

            if (filters.search) {
                filtered = filtered.filter(order =>
                    order.items.some(item =>
                        item.productName.toLowerCase().includes(filters.search.toLowerCase())
                    )
                );
            }

            setFilteredOrders(filtered);
        };

        applyFilters();
    }, [orders, orderItems, products, filters]);

    const handleSort = () => {
        const sortedOrders = [...filteredOrders].sort((a, b) => {
            return sortOrder === 'asc' 
                ? new Date(a.order_date) - new Date(b.order_date)
                : new Date(b.order_date) - new Date(a.order_date);
        });
        setFilteredOrders(sortedOrders);
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    };

    const openModal = (imageSrc) => {
        setModalImage(imageSrc);
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
        setModalImage(null);
    };

    const toggleExpand = (orderId) => {
        setExpandedOrders(prev => {
            const newSet = new Set(prev);
            newSet.has(orderId) ? newSet.delete(orderId) : newSet.add(orderId);
            return newSet;
        });
    };

    const cancelOrder = async (orderId) => {
        try {
            await axios.put(`${APIURL}/orders/${orderId}/cancel`, {}, { headers: { 'Authorization': 'ubpatil' } });
            // Refresh data after canceling
            const response = await axios.get(`${APIURL}/myorders`, { headers: { 'Authorization': 'ubpatil' }, params: { userId: currentUserId } });
            setOrders(response.data);
        } catch (error) {
            console.error('Error canceling order:', error);
        }
    };

    return (
        <div className="order-details-container"> 
            <Grid container spacing={2} className="filtersOrder">
                <Grid item xs={12} md={4}>
                    <Select
                        fullWidth
                        onChange={(e) => setFilters(prev => ({ ...prev, status: e.target.value }))}
                        value={filters.status}
                    >
                        <MenuItem value="">All Statuses</MenuItem>
                        <MenuItem value="Pending">Pending</MenuItem>
                        <MenuItem value="completed">Completed</MenuItem>
                        <MenuItem value="shipped">Shipped</MenuItem>
                        <MenuItem value="Cancelled">Cancelled</MenuItem>
                    </Select>
                </Grid>
                <Grid item xs={12} md={4}>
                    <TextField
                        fullWidth
                        placeholder="Search by product name..."
                        onChange={(e) => setFilters(prev => ({ ...prev, search: e.target.value }))}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <Button variant="contained" onClick={handleSort}>
                        Sort by Date ({sortOrder === 'asc' ? 'Ascending' : 'Descending'})
                    </Button>
                </Grid>
            </Grid>
            <Table className="order-details-table">
                <TableHead>
                    <TableRow>
                        <TableCell>Enquiry ID</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Username</TableCell>
                        <TableCell>Quantity</TableCell>
                        <TableCell>Price</TableCell>
                        <TableCell>Enquiry Date</TableCell>
                        <TableCell>User Email</TableCell>
                        <TableCell>User Mobile</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Action</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {filteredOrders.map(order => (
                        <React.Fragment key={order.order_id}>
                            <TableRow>
                                <TableCell colSpan={10}>
                                    <Button
                                        variant="text"
                                        onClick={() => toggleExpand(order.order_id)}
                                    >
                                        {expandedOrders.has(order.order_id) ? 'Collapse' : 'Expand'}
                                    </Button>
                                    Enquiry ID: {order.order_id} | Status: {order.status}
                                    {order.items.length > 0 && (
                                        <div className={`order-details ${expandedOrders.has(order.order_id) ? 'visible' : 'hidden'}`}>
                                            <strong>Enquiry Details:</strong>
                                            <Table className="order-details-sub-table">
                                                <TableBody>
                                                    {order.items.map(item => {
                                                        const user = users.find(u => u.id === order.user_id);
                                                        return (
                                                            <TableRow key={item.order_item_id}>
                                                                <TableCell>
                                                                    <div className="product-image-wrapper">
                                                                        <img
                                                                            src={`${APIURL}/Images/${item.product_id}.jpg`}
                                                                            alt={item.productName}
                                                                            className="product-image"
                                                                            onClick={() => openModal(`${APIURL}/Images/${item.product_id}.jpg`)}
                                                                        />
                                                                        <div className="product-name">
                                                                            {item.productName}
                                                                        </div>
                                                                    </div>
                                                                </TableCell>
                                                                <TableCell>{item.quantity}</TableCell>
                                                                <TableCell>{item.price}</TableCell>
                                                                <TableCell>{order.order_date}</TableCell>
                                                                <TableCell>{user?.email}</TableCell>
                                                                <TableCell>{user?.mobile}</TableCell>
                                                                <TableCell>{order.status}</TableCell>
                                                                <TableCell>
                                                                    {order.status === 'Pending' && (
                                                                        <Button
                                                                            onClick={() => cancelOrder(order.order_id)}
                                                                            variant="contained"
                                                                            color="secondary"
                                                                        >
                                                                            Cancel
                                                                        </Button>
                                                                    )}
                                                                </TableCell>
                                                            </TableRow>
                                                        );
                                                    })}
                                                </TableBody>
                                            </Table>
                                        </div>
                                    )}
                                </TableCell>
                            </TableRow>
                        </React.Fragment>
                    ))}
                </TableBody>
            </Table>
            <ImageModal
                imageSrc={modalImage}
                isOpen={modalOpen}
                onClose={closeModal}
            />
        </div>
    );
}

export default ViewOrders; 
