import React, { useState } from 'react';
import { Container, TextField, Button, Typography, Link, CircularProgress, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import APIURL from '../config'; // Your API URL

function LoginPage() {
  const [emailOrMobile, setEmailOrMobile] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [forgotEmailOrMobile, setForgotEmailOrMobile] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${APIURL}/login`, {
        email: emailOrMobile,
        mobile: emailOrMobile,
        password
      }, { headers: { 'Authorization': 'ubpatil' } });

      // Save data to local storage
      localStorage.setItem('authToken', response.data.token);
      localStorage.setItem('userId', response.data.userId);
      localStorage.setItem('isAdmin', response.data.isAdmin ? 'true' : 'false');
      localStorage.setItem('username', response.data.userName);

      // Trigger re-render on Navbar
      window.dispatchEvent(new Event('storage'));

      // Redirect to homepage
      navigate('/');
    } catch (error) {
      console.error('Login error:', error);
      setMessage('Login failed. Please check your credentials.');
    }
  };

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await axios.post(`${APIURL}/forgot-password`, { emailOrMobile: forgotEmailOrMobile }, { headers: { 'Authorization': 'ubpatil' } });
      setMessage('Password reset link sent to your email or mobile.');
      setForgotEmailOrMobile('');
      setShowForgotPassword(false);
    } catch (error) {
      console.error('Forgot password error:', error);
      setMessage('Error sending password reset link.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="xs" sx={{ mt: 8 }}>
      <Typography variant="h4" gutterBottom align="center">
        {showForgotPassword ? 'Forgot Password' : 'Login'}
      </Typography>

      {message && (
        <Typography variant="body2" color="error" align="center">
          {message}
        </Typography>
      )}

      {showForgotPassword ? (
        <Box component="form" onSubmit={handleForgotPassword} sx={{ mt: 2 }}>
          <TextField
            fullWidth
            label="Email or Mobile"
            value={forgotEmailOrMobile}
            onChange={(e) => setForgotEmailOrMobile(e.target.value)}
            required
            margin="normal"
            InputProps={{ style: { padding: '10px' } }} // Adjust padding
          />
          <Button
            fullWidth
            type="submit"
            variant="contained"
            color="primary"
            sx={{ mt: 2, mb: 2 }}
          >
            Send Reset Link
          </Button>
          <Button fullWidth color="secondary" onClick={() => setShowForgotPassword(false)}>
            Back to Login
          </Button>
          {loading && <CircularProgress sx={{ mt: 2 }} />}
        </Box>
      ) : (
        <Box component="form" onSubmit={handleLogin} sx={{ mt: 2 }}>
          <TextField
            fullWidth
            label="Email or Mobile"
            value={emailOrMobile}
            onChange={(e) => setEmailOrMobile(e.target.value)}
            required
            margin="normal"
            InputProps={{ style: { padding: '10px' } }} // Adjust padding
          />
          <TextField
            fullWidth
            label="Password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            margin="normal"
            InputProps={{ style: { padding: '10px' } }} // Adjust padding
          />
          <Button
            fullWidth
            type="submit"
            variant="contained"
            color="primary"
            sx={{ mt: 2, mb: 2 }}
          >
            Login
          </Button>
          <Typography align="center">
            <Link href="#" onClick={() => setShowForgotPassword(true)} underline="hover">
              Forgot password?
            </Link>
          </Typography>
        </Box>
      )}

      <Typography align="center" sx={{ mt: 2 }}>
        Don't have an account?{' '}
        <Link href="/signup" underline="hover">
          Sign up
        </Link>
      </Typography>
    </Container>
  );
}

export default LoginPage;
