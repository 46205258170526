import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Typography, Stack, Box } from '@mui/material';
import { ChevronRight } from '@mui/icons-material';

const Breadcrumbs = () => {
  const location = useLocation();
  const pathnames = location.pathname.split('/').filter(x => x);

  const defaultBreadcrumbs = [
    { label: 'Home', href: '/' },
    { label: 'Products', href: '/products' },
  ];

  const breadcrumbItems = pathnames.length === 0
    ? defaultBreadcrumbs
    : [
        { label: 'Home', href: '/' },
        ...pathnames.map((pathname, index) => {
          const to = `/${pathnames.slice(0, index + 1).join('/')}`;
          return {
            label: 
              pathname === 'businessdirectory' ? 'Business Directory' :
              pathname === 'ratantata' ? 'Ratan Tata' :
              pathname === 'businessregistration' ? 'Business Registration' :
              pathname.charAt(0).toUpperCase() + pathname.slice(1),
            href: to
          };
        })
      ];

  return (
    <Box 
      sx={{ 
        padding: '10px 15px', 
        backgroundColor: '#f5f5f5', 
        borderRadius: '5px', 
        margin: '10px 0', 
        boxShadow: 2 // Adding a subtle shadow
      }} 
      aria-label="Breadcrumb"
    >
      <Stack direction="row" spacing={1} alignItems="center">
        {breadcrumbItems.map((item, index) => (
          <React.Fragment key={item.href}>
            {index > 0 && <ChevronRight fontSize="small" sx={{ color: '#3f51b5' }} />}
            {index === breadcrumbItems.length - 1 ? (
              <Typography 
                variant="body2" 
                color="textPrimary" 
                fontWeight="bold"
              >
                {item.label}
              </Typography>
            ) : (
              <Link 
                to={item.href} 
                style={{ textDecoration: 'none' }} 
                aria-label={`Go to ${item.label}`}
              >
                <Typography 
                  variant="body2" 
                  color="primary" 
                  sx={{ 
                    '&:hover': { 
                      textDecoration: 'underline',
                      color: '#2c387e'
                    } 
                  }}
                >
                  {item.label}
                </Typography>
              </Link>
            )}
          </React.Fragment>
        ))}
      </Stack>
    </Box>
  );
};

export default Breadcrumbs;
