import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function Logout() {
  const navigate = useNavigate();

  useEffect(() => {
    // Function to check if the token is expired
    const checkTokenExpiration = () => {
      const token = localStorage.getItem('authToken');
      
      // Assuming token has an expiration time stored in it, decode or check expiration time
      const expirationTime = localStorage.getItem('tokenExpiration'); // Example key for token expiration
      
      if (!token || Date.now() >= expirationTime) {
        handleLogout();
      }
    };

    // Call checkTokenExpiration initially
    checkTokenExpiration();

    // Set an interval to periodically check for token expiration
    const intervalId = setInterval(() => {
      checkTokenExpiration();
    }, 60000); // Check every minute

    // Cleanup the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []);

  const handleLogout = () => {
    // Clear localStorage
    localStorage.removeItem('authToken');
    localStorage.removeItem('tokenExpiration'); // Optional, if you store token expiration
    localStorage.removeItem('userId');
    localStorage.removeItem('isAdmin');

    // Force re-render of Navbar by triggering a storage event
    window.dispatchEvent(new Event('storage'));

    // Redirect to login or homepage
    navigate('/login');
  };

  return null; // No UI component is necessary since it's automatic
}

export default Logout;
