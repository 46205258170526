import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  TextField,
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  TableSortLabel, // Import TableSortLabel for sorting
} from '@mui/material';
import axios from 'axios';
import APIURL from '../../config'; // Import your global API URL

const CategoryManagementPage = () => {
  const [categoryId, setCategoryId] = useState('');
  const [categoryName, setCategoryName] = useState('');
  const [categories, setCategories] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [editMode, setEditMode] = useState(false);
  const [currentCategoryId, setCurrentCategoryId] = useState('');
  
  // Sort and filter states
  const [sortBy, setSortBy] = useState('id'); // Default sort by 'id'
  const [sortDirection, setSortDirection] = useState('asc'); // Sort ascending by default
  const [filterText, setFilterText] = useState(''); // Filter input for category name

  // Pagination state
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  // Fetch categories from the backend
  const fetchCategories = async () => {
    try {
      const response = await axios.get(`${APIURL}/categoriesadmin`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'ubpatil', // Replace with your authorization token if needed
        },
      });
      setCategories(response.data); // Set categories state with fetched data
    } catch (error) {
      console.error(error);
      setErrorMessage('Failed to load categories');
    }
  };

  useEffect(() => {
    fetchCategories(); // Call the function to fetch categories
  }, []);

  // Handle form submission for adding/updating category
  const handleSubmit = async (e) => {
    e.preventDefault();

    const categoryData = {
      id: categoryId,
      name: categoryName,
    };

    try {
      if (editMode) {
        // Update category if in edit mode
        const response = await axios.put(`${APIURL}/categories/${currentCategoryId}`, categoryData, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'ubpatil',
          },
        });

        if (response.data.success) {
          setSuccessMessage('Category updated successfully!');
        }
      } else {
        // Create new category
        const response = await axios.post(`${APIURL}/categoriesadmin`, categoryData, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'ubpatil',
          },
        });

        if (response.data.success) {
          setSuccessMessage('Category added successfully!');
        }
      }

      // Reset form
      setCategoryId(''); // Category ID is not used in the input field
      setCategoryName('');
      setEditMode(false);
      setCurrentCategoryId('');
      setErrorMessage('');
      fetchCategories(); // Refresh category list
    } catch (error) {
      console.error(error);
      setErrorMessage('An error occurred. Please try again later.');
    }
  };

  // Handle edit category
  const handleEdit = (category) => {
    setCategoryId(category.id); // Set Category ID but keep it disabled
    setCategoryName(category.name);
    setEditMode(true);
    setCurrentCategoryId(category.id);
  };

  // Handle delete category
  const handleDelete = async (categoryId) => {
    if (window.confirm('Are you sure you want to delete this category?')) {
      try {
        const response = await axios.delete(`${APIURL}/categories/${categoryId}`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'ubpatil',
          },
        });

        if (response.data.success) {
          setSuccessMessage('Category deleted successfully!');
          fetchCategories(); // Refresh category list
        }
      } catch (error) {
        console.error(error);
        setErrorMessage('Failed to delete category.');
      }
    }
  };

  // Handle activate/deactivate category
  const handleToggleActive = async (categoryId, isActive) => {
    try {
      const response = await axios.patch(`${APIURL}/categories/${categoryId}`, { is_active: !isActive }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'ubpatil',
        },
      });

      if (response.data.success) {
        setSuccessMessage(`Category ${isActive ? 'deactivated' : 'activated'} successfully!`);
        fetchCategories(); // Refresh category list
      }
    } catch (error) {
      console.error(error);
      setErrorMessage('Failed to toggle category status.');
    }
  };

  // Sorting logic
  const handleSort = (column) => {
    const isAsc = sortBy === column && sortDirection === 'asc';
    setSortDirection(isAsc ? 'desc' : 'asc');
    setSortBy(column);
  };

  // Filter logic for category name
  const filteredCategories = categories
    .filter((category) => category.name.toLowerCase().includes(filterText.toLowerCase()))
    .sort((a, b) => {
      if (sortDirection === 'asc') {
        return a[sortBy] > b[sortBy] ? 1 : -1;
      } else {
        return a[sortBy] < b[sortBy] ? 1 : -1;
      }
    });

  // Pagination handlers
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Container>
      <Typography variant="h4" align="center" gutterBottom>
        Category Management
      </Typography>
      {errorMessage && <Typography color="error">{errorMessage}</Typography>}
      {successMessage && <Typography color="primary">{successMessage}</Typography>}
      
      {/* Filter Input */}
      <TextField
        fullWidth
        label="Filter by Category Name"
        variant="outlined"
        value={filterText}
        onChange={(e) => setFilterText(e.target.value)}
        style={{ marginBottom: '20px' }}
      />

      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Category ID"
              variant="outlined"
              value={categoryId} // Bind the ID but it will be disabled
              disabled // Always disabled
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Category Name"
              variant="outlined"
              value={categoryName}
              onChange={(e) => setCategoryName(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <Button type="submit" variant="contained" color="primary">
              {editMode ? 'Update Category' : 'Add Category'}
            </Button>
          </Grid>
        </Grid>
      </form>

      <TableContainer component={Paper} style={{ marginTop: '20px' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <TableSortLabel
                  active={sortBy === 'id'}
                  direction={sortDirection}
                  onClick={() => handleSort('id')}
                >
                  Category ID
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortBy === 'name'}
                  direction={sortDirection}
                  onClick={() => handleSort('name')}
                >
                  Category Name
                </TableSortLabel>
              </TableCell>
              <TableCell>Active</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredCategories.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((category) => (
              <TableRow key={category.id}>
                <TableCell>{category.id}</TableCell>
                <TableCell>{category.name}</TableCell>
                <TableCell>{category.is_active ? 'Yes' : 'No'}</TableCell>
                <TableCell>
                  <Button onClick={() => handleEdit(category)} variant="outlined" color="primary" size="small">
                    Edit
                  </Button>
                  <Button onClick={() => handleToggleActive(category.id, category.is_active)} variant="outlined" color={category.is_active ? 'default' : 'primary'} size="small">
                    {category.is_active ? 'Deactivate' : 'Activate'}
                  </Button>
                  <Button onClick={() => handleDelete(category.id)} variant="outlined" color="secondary" size="small">
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        component="div"
        count={filteredCategories.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Container>
  );
};

export default CategoryManagementPage;
